import {Tooltip} from 'antd';
import classNames from 'classnames';
import {forwardRef, PropsWithChildren, ReactElement} from 'react';
import { getKeyUpHandler } from '../../../utils/helpers';
import {Text} from '../text';
import Input, {InputProps, InputType} from './input';

export const enum RadioType {
  Button,
}
export type RadioProps = InputProps & {
  radioValue?: string | number;
  allowUncheck?: boolean;
  radioType?: RadioType;
  icon?: string;
  tooltip?: string;
  labelProps?: Record<string, any>;
};

export const RadioInner: React.FC<RadioProps> = forwardRef<
  ReactElement,
  PropsWithChildren<RadioProps>
>(
  (
    {
      children,
      label,
      tooltip,
      icon,
      className,
      value,
      isDirty,
      isTouched,
      invalid,
      error,
      radioValue,
      allowUncheck,
      radioType,
      labelProps,
      ...inputProps
    },
    ref
  ) => {
    let input = (
      <input
        type="radio"
        ref={ref as any} // NOSONAR
        value={radioValue}
        checked={value === radioValue}
        onChange={() => {}}
        {...inputProps}
      />
    );

    const isButton = radioType === RadioType.Button;
    let content = (
      <Tooltip title={tooltip}>
        <label // NOSONAR
          {...(isButton ? null : { radio: "" })}
          className={classNames(className, {
            error: invalid,
            touched: isTouched,
            dirty: isDirty,
          })}
          onClick={(e) => {
            if (allowUncheck && value === radioValue) {
              !!inputProps.onChange && inputProps.onChange(null as any);
              e.preventDefault();
            }
          }}
          onKeyUp={getKeyUpHandler()}
          {...labelProps}
        >
          {input}
          {isButton ? (
            <wrap>
              {icon && <icon>{icon}</icon>}
              {label && <Text>{label}</Text>}
            </wrap>
          ) : (
            <>
              <box>
                <check></check>
                <ink></ink>
              </box>
              <Text>{label}</Text>
            </>
          )}
        </label>
      </Tooltip>
    );

    return content;
  }
);

const Radio: React.FC<RadioProps> = forwardRef<
  ReactElement,
  PropsWithChildren<RadioProps>
>((props, ref) => {
  return (
    <Input
      ref={ref}
      radio=""
      {...(props as InputProps)}
      type={InputType.Radio}
    />
  );
});

export default Radio;
