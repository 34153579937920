import { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useGetSatcFamilyInfoQuery } from '../../store/satc/satcApi';
import Loader from '../../components/shared/loader';

import useTranslation from '../../utils/hooks/useTranslation';
import { useGetBreadcrumbQuery } from '../../store/breadcrumb/breadcrumbApi';
import { getAppUrl, getBreadcrumbQueryParams } from '../../project/helpers';
import { useAppSelector } from '../../store/hooks';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { APP_URL } from '../../project/defines';

const SatcFamilyNavItem: React.FC<{ title: string; page: string, disabled: boolean, uuid: string }> = ({title, page, disabled, uuid}) => {
  const { t } = useTranslation();
  const item = 
    <group data-name="tab-nav-item" data-cursor="pointer" data-width="auto" data-interactive="" data-space="10" data-radius="5" data-border="inset" data-index="0" data-effect="material" className={disabled ? "disabled" : ""}>
      <text data-weight="700" data-ellipsis="">{t(title)}</text>
    </group>;
  return (
    disabled ? item
    :
    <Link to={
      getAppUrl(APP_URL.FamilyDetail, {
        routeParams: {
          id: uuid,
          tabname: page,
        },
      })
    }
    >
      {item}
    </Link>
  )
};

const SatcFamilyNav: React.FC = () => {
  const { id, reseau_uuid } = useParams();
  const location = useLocation();
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [famille, setFamille] = useState('');
  const { data, isLoading, isFetching } = useGetSatcFamilyInfoQuery({ famille_uuid: famille, lang, pays_uuid }, {skip: !famille});
  const loading = isLoading || isFetching;
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);

  const queryParamsByType = useMemo(() => {
    return getBreadcrumbQueryParams(location.pathname, id, reseau_uuid);
  }, [location.pathname, id, reseau_uuid]);

  const { data: breadcrumbData } = useGetBreadcrumbQuery(
    { univers_uuid: univers?.uuid ?? '', lang, ...queryParamsByType },
    { skip: !id || !univers?.uuid || !lang || !queryParamsByType }
  );

  useEffect(() => {
    if (breadcrumbData && breadcrumbData.length > 0) {
      let famille_uuid = '';
      breadcrumbData.slice().reverse().forEach(element => {
        if(element.type === 'famille' && famille_uuid === ''){
          
          famille_uuid = element.uuid;
          setFamille(element.uuid);
        }
      });
    }
  }, [breadcrumbData]);



  return loading ? (
    <>
    <group data-gap="5">
      <Loader />
    </group>
    <group data-height="20"></group>
    </>
  ) : data ? ( // NOSONAR
    
<>
<group data-gap="5">
        <SatcFamilyNavItem title='satc.detail.tabs.general.title' page="general" disabled={false} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.docTech.title' page="doc_technique" disabled={isTabDisabled('doc_technique', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.videoTutos.title' page="videos" disabled={isTabDisabled('videos', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.ficheDefaut.title' page="fiches_defaut" disabled={isTabDisabled('fiches_defaut', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.procDepannage.title' page="proc_depannage" disabled={isTabDisabled('proc_depannage', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.phototeque.title' page="photos" disabled={isTabDisabled('photos', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.expertQualite.title' page="defauts" disabled={isTabDisabled('defauts', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.noticeRemplacement.title' page="not_remplacement" disabled={isTabDisabled('not_remplacement', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.accessoires.title' page="accessoires" disabled={isTabDisabled('accessoires', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.evolutions.title' page="evolutions" disabled={isTabDisabled('evolutions', data)} uuid={famille} />
        <SatcFamilyNavItem title='satc.detail.tabs.pvCertifs.title' page="pv_certifs" disabled={isTabDisabled('pv_certifs', data)} uuid={famille} />
      </group>
      <group data-height="20"></group>
</>
          
      
  ) : (
    <></>
  );
};

export default SatcFamilyNav;

const isTabDisabled = (tabname: string, data: any) => {
  switch (tabname) {
    case 'general':
    case 'proc_depannage':
    case 'photos':
    case 'defauts':
    case 'not_remplacement':
    case 'pv_certifs':
      return !Object.keys(data[tabname]).length;
    case 'doc_technique':
      return !Object.keys(data[tabname].PFS_DOC_PRODUIT).length && !Object.keys(data[tabname].PFS_DOC_SAV).length;
    case 'videos':
      return !data[tabname].pfs.length && !data[tabname].docga.length;
    case 'fiches_defaut':
      return !data[tabname].docga.length;
    case 'accessoires':
      return !data[tabname].pfs.length;
    case 'evolutions':
      return !data[tabname].length;
    default:
      return false;
  }
};
