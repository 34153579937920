import { FetchArgs, fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
import { LOCAL_STORAGE_KEYS } from '../project/defines';
import { getApiUrl, getUniversFromUrl, getSSOByUnivers } from '../project/helpers';
import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from '../utils/helpers/localStorage';
import { reloadIsLoggedInFromStorage } from './auth/authSlice';

const app_back_api_url = process.env.REACT_APP_API_TOKEN_URL;
const end_point = process.env.REACT_APP_API_END_POINT;

const baseInnerQuery = async (apiName: string, args: FetchArgs, api: any) => {
  const newArgs = { ...args };

  const session_code = getLocalStorageItem(LOCAL_STORAGE_KEYS.SessionCode);
  if (apiName !== 'translations' && apiName !== 'univers' && session_code) {
    newArgs.params = { ...newArgs.params, session_code };
  }

  let http_header = "Authorization";
  let http_bearer = "Bearer ";
  if (!end_point) {
    http_header = "x-Authorization";
    //http_bearer = "";
  }

  return fetchBaseQuery({
    baseUrl: `${getApiUrl(getUniversFromUrl())}/${apiName}`,
    prepareHeaders: (headers: Headers) =>{
      let user_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
      if (user_token){
        headers.set(http_header, http_bearer + user_token);
      } else {
        let app_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
        if (app_token){
          headers.set(http_header, http_bearer + app_token);
        }
      }
    }
    //credentials: 'include',
  })(newArgs, api, {});
};

const baseTokenQuery = async(args: FetchArgs, api: any) =>{ // NOSONAR
  let univers = getSSOByUnivers();

  let fd = "grant_type=client_credentials&univers=" + univers;
  let user_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
  if (user_token){
    let refresh_token = getLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken);
    fd = "grant_type=refresh_token";
    fd += "&univers="+ univers.replace('-fr', '');
    fd += "&refresh_token=" + refresh_token;
    fd += `&redirect_uri=${window.location.origin}/wso/callback`;
  }
  let headers = {
    "Content-Type": "application/x-www-form-urlencoded"
  };
  const fetchOptions = { 
    method: "POST",
    body: fd
  };
  let params = {
    ...fetchOptions, 
      headers: headers
  };

  await fetch(`${app_back_api_url ?? ''}/api/token`, params as any)
        .then((response) => {
            if (response && (response.status === 401 || response.status === 400)){
              if (user_token){
                return {
                  access_token: null,
                  refresh_token: null,
                };
              }    
            }
            return response.json()
        })
        .then((result) => {
          if (result){
            if (user_token){
              if (result.access_token){
                setLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken, result.access_token);
                setLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken, result.refresh_token);
              } else {
                removeLocalStorageItem(LOCAL_STORAGE_KEYS.UserToken);
                removeLocalStorageItem(LOCAL_STORAGE_KEYS.RefreshToken);
                removeLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
                reloadIsLoggedInFromStorage();
              }
            } else {
              if (result.access_token) {
                setLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken, result.access_token);
              } else {
                removeLocalStorageItem(LOCAL_STORAGE_KEYS.AppToken);
              }
            }
          }
        })
        .catch((error) =>{
          console.log(error);
        });

    return null;
};

let promis: Promise<any> | null = null;
const baseQuery = (apiName: string) => async (args: FetchArgs, api: any) => {
  const { dispatch } = api;

  let response = await baseInnerQuery(apiName, args, api);

  if (response.error?.status === 401 && args.url !== 'me') {
    removeLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
    dispatch(reloadIsLoggedInFromStorage());
  }

  if (response.error && ((response.error as any).originalStatus === 401 || (response.error as any).originalStatus === 403)){
    localStorage.removeItem(LOCAL_STORAGE_KEYS.AppToken);

    if (!promis) {
      promis = new Promise((resolve)=>{
        baseTokenQuery(args, api).then(()=>{
          resolve(undefined);
          promis = null;
        });
      });
    }

    if (promis != null){
      response = await promis.then(async(resolve)=>{
        return await baseInnerQuery(apiName, args, api);
      })

      if (response.error?.status === 401 && args.url !== 'me') {
        removeLocalStorageItem(LOCAL_STORAGE_KEYS.Auth);
        dispatch(reloadIsLoggedInFromStorage());
      }

    }
  }

  return response;
};

export default baseQuery;
