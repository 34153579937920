import { message } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import { HEADER_TOOLBAR_NAVIGATION_DATA, MenuKeys } from '../../brands/thermor/defines';
import { NavigationItemProps } from '../../components/shared/navigation/navigationItem';
import { DEFAULT_DATE_FORMAT_WITH_TIME, SLUG_ACCESS_KEYS } from '../../project/defines';
import { checkAccess, isWarrantyRequestAllowedForUniverse } from '../../project/helpers';
import { RootState } from '../../store';
import { AuthModel } from '../../store/auth/authModels';
import { Country } from '../../store/country/countryModels';
import { Maintenance } from '../../store/univers/universModels';
import { ContextMenuDirections, Position } from '../hooks/useOpener';

dayjs.extend(localizedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);

export const redirectToUrlWithPostRequest = (url: string) => {
  const form = document.createElement('form');
  form.method = 'post';
  form.action = url;

  form.style.display = 'none';
  document.body.appendChild(form);

  form.submit();
};

export const getBlobUrl = (blob: Blob) => {
  const url = window.URL || window.webkitURL;
  const link = url.createObjectURL(blob);
  return link;
};

export const downloadBlob = (blob: Blob, fileName: string = '') => {
  const link = getBlobUrl(blob);
  downloadByLink(link, fileName);
};

export const downloadByLink = (link: string, fileName: string = '') => {
  const a = document.createElement('a');
  a.setAttribute('download', fileName || 'file');
  a.setAttribute('href', link);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const convertToBase64 = (obj: any) => {
  let json = JSON.stringify(
    obj,
    (k, val) => (0 === val || (null === val ? '' : val)) && ('object' === typeof val ? val : String(val))
  );

  return Buffer.from(json).toString('base64');
};

export const convertBase64ToBlob = (b64Data: string, contentType: string, sliceSize: number = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getBlobUrlFromBase64 = (dataUrl: string) => {
  let contentType = dataUrl?.includes('image') ? 'image/jpeg' : 'application/pdf';
  return getBlobUrl(convertBase64ToBlob(dataUrl?.split(',')[1], contentType));
};

export const formatDate = (date: Date, format?: string) => {
  return dayjs(date).format(format ?? 'L');
};

export const formatDateTime = (date: Date, format?: string) => {
  return dayjs(date).format(format ?? 'L LT');
};

export function getDateXDaysAgo(numOfDays: number, date = new Date()) {
  const daysAgo = new Date(date.getTime());
  daysAgo.setDate(date.getDate() - numOfDays);
  return daysAgo;
}

export const getDatePeriodStr = (format: string = DEFAULT_DATE_FORMAT_WITH_TIME) => {
  return {
    dateFrom: formatDate(getDateXDaysAgo(30), format),
    dateTo: formatDate(new Date(), format),
  };
};

export function getUuidByCodePays(listePays: Country[], codePays: string) {
  if (!listePays) return null;
  let pays = listePays.find((p) => p.code === codePays);
  return pays ? pays.uuid : null;
}

export function getIsBetweenDates(maintenance: Maintenance) {
  let current = dayjs().utc().format('YYYY-MM-DD HH:mm:ss');
  return dayjs(current).isBetween(dayjs(maintenance.disabled_login_start), dayjs(maintenance.disabled_login_end));
}

export const calculatePositionUpToDirection: (
  direction: ContextMenuDirections,
  openerRect: React.RefObject<any>,
  wrapperRect: React.RefObject<any>,
  isFixed: boolean,
  isSticky?: boolean
) => Position = (direction, refOpener, refWrapper, isFixed, isSticky) => { // NOSONAR
  let top, left;

  let openerRect = refOpener.current.getBoundingClientRect();
  let wrapperRect = refWrapper.current.getBoundingClientRect();
  let bodyRect = document.body.getBoundingClientRect();
  let topUpToFixed;

  switch (direction) {
    case 'top':
      top = openerRect.top - bodyRect.top - wrapperRect.height;
      if (top < 0) {
        top = openerRect.bottom - bodyRect.top;
        if (top + wrapperRect.height > bodyRect.height) {
          top = 0;
        }
      }
      left = (openerRect.right + openerRect.left) / 2 - wrapperRect.width / 2;
      if (left < 0) {
        left = 0;
      } else if (left + wrapperRect.width > bodyRect.right) {
        left = bodyRect.right - wrapperRect.width - 5;
      }
      break;
    case 'bottom':
      topUpToFixed = isFixed ? 0 : bodyRect.top;
      top = openerRect.bottom - (isSticky ? topUpToFixed : bodyRect.top);
      if (top + wrapperRect.height > bodyRect.height) {
        top = openerRect.top - bodyRect.top - wrapperRect.height;
        if (top < 0) {
          top = bodyRect.height - wrapperRect.height;
        }
      }
      left = (openerRect.right + openerRect.left) / 2 - wrapperRect.width / 2;
      if (left < 0) {
        left = 0;
      } else if (left + wrapperRect.width > bodyRect.right) {
        left = bodyRect.right - wrapperRect.width;
      }
      break;
    case 'left':
      top = (openerRect.top + openerRect.bottom) / 2 - wrapperRect.height / 2 - bodyRect.top;
      if (top < 0) {
        top = 0;
      } else if (top + wrapperRect.height > bodyRect.height) {
        top = bodyRect.height - wrapperRect.height;
        if (top < 0) {
          top = 0;
        }
      }
      left = openerRect.left - wrapperRect.width;
      if (left < 0) {
        left = openerRect.right;
        if (left + wrapperRect.width > bodyRect.width) {
          left = 0;
        }
      }
      break;
    case 'right':
      top = (openerRect.top + openerRect.bottom) / 2 - wrapperRect.height / 2 - bodyRect.top;
      if (top < 0) {
        top = 0;
      } else if (top + wrapperRect.height > bodyRect.height) {
        top = bodyRect.height - wrapperRect.height;
        if (top < 0) {
          top = 0;
        }
      }

      left = openerRect.right;
      if (left + wrapperRect.width > bodyRect.width) {
        left = openerRect.left - wrapperRect.width;
        if (left < 0) {
          left = bodyRect.width - wrapperRect.width;
        }
      }
      break;
    case 'topLeft':
      top = openerRect.top - bodyRect.top - wrapperRect.height;
      if (top < 0) {
        top = openerRect.bottom - bodyRect.top;
        if (top + wrapperRect.height > bodyRect.height) {
          top = 0;
        }
      }
      left = openerRect.left;
      if (left + wrapperRect.width > bodyRect.width) {
        left = bodyRect.width - wrapperRect.width;
      }
      break;
    case 'topRight':
      top = openerRect.top - bodyRect.top - wrapperRect.height;
      if (top < 0) {
        top = openerRect.bottom - bodyRect.top;
        if (top + wrapperRect.height > bodyRect.height) {
          top = 0;
        }
      }
      left = openerRect.right - wrapperRect.width;
      if (left < 0) {
        left = 0;
      }
      break;
    case 'bottomLeft':
      topUpToFixed = isFixed ? 0 : bodyRect.top;
      top = openerRect.bottom - (isSticky ? topUpToFixed : bodyRect.top);
      if (top + wrapperRect.height > bodyRect.height) {
        top = openerRect.top - bodyRect.top - wrapperRect.height;
        if (top < 0) {
          top = bodyRect.height - wrapperRect.height - 5;
        }
      }
      left = openerRect.left;
      if (left + wrapperRect.width > bodyRect.width) {
        left = bodyRect.width - wrapperRect.width;
      }
      break;
    case 'bottomRight':
      topUpToFixed = isFixed ? 0 : bodyRect.top;
      top = openerRect.bottom - (isSticky ? topUpToFixed : bodyRect.top);
      if (top + wrapperRect.height > bodyRect.height) {
        top = openerRect.top - bodyRect.top - wrapperRect.height;
        if (top < 0) {
          top = bodyRect.height - wrapperRect.height - 5;
        }
      }
      left = openerRect.right - wrapperRect.width;
      if (left < 0) {
        left = 0;
      }
      break;
    case 'bottomForce':
      top = openerRect.bottom + (isFixed ? 0 : window.scrollY);
      left = (openerRect.right + openerRect.left) / 2 - wrapperRect.width / 2;
      if (left < 0) {
        left = 0;
      } else if (left + wrapperRect.width > bodyRect.right) {
        left = bodyRect.right - wrapperRect.width;
      }
      break;
  }
  return { top, left } as Position;
};

export const copyToClipboard = (t: (k: string) => string, content: any) => {
  navigator.clipboard.writeText(content).then(
    () => {
      message.success(t('general.copyToClipboard'));
    },
    (error) => {
      console.log(error);
    }
  );
};

export const getFilteredToolbarMenuItems = (authUser: AuthModel, state: RootState) => {
  const result: NavigationItemProps[] = [];
  if (!authUser.uuid) {
    return result;
  }

  HEADER_TOOLBAR_NAVIGATION_DATA.forEach((item) => {
    let hasAccess = false;
    if (item.key === MenuKeys.Commande) {
      hasAccess = checkAccess(authUser, SLUG_ACCESS_KEYS.Commande);
    } else if (item.key === MenuKeys.Devis) {
      hasAccess = !!state.components.devisCount;
    } else if (item.key === MenuKeys.Warranty) {
      hasAccess = hasWarrantyAccess(state);
    } else if (item.key === MenuKeys.Panier) {
      hasAccess = checkAccess(authUser, SLUG_ACCESS_KEYS.Commande) || state.components.cartCount > 0;
      item = { ...item, count: state.components.cartCount };
    }
    if (hasAccess) {
      result.push(item);
    }
  });

  return result;
};

export const hasWarrantyAccess = (state: RootState) => {
  const { siteData, auth: authUser } = state;

  const hideDemandeGarantie =
    siteData?.universInfo?.settings?.hide_demande_garantie ||
    !isWarrantyRequestAllowedForUniverse(siteData?.universInfo?.univers?.domain ?? '');

  return !hideDemandeGarantie && checkAccess(authUser, SLUG_ACCESS_KEYS.Sav) && authUser.has_access_demande_garantie;

};

export const getKeyUpHandler:any = (callback?: Function, key?: string | string[] | KeyboardEvent) => {
  return (e: KeyboardEvent) => {
    if (callback && ((typeof key === 'string' && e.key === key) || (Array.isArray(key) && key.includes(e.key)))) {
      callback();
    }
  };

};