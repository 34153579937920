import { disableArticleAddtoCartButton, showArticleCreditCardIcon, redirectToUrl } from '../..//project/helpers';
import Button from '../../components/shared/button';
import { SvgLoader } from '../../components/svg';
import { SESSION_STORAGE_KEYS } from '../../project/defines';
import { getProSSO } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';
import { useAddToCartHook, useAppSelector } from '../../store/hooks';
import { isDisabledLogin } from '../../store/sideData/siteDataSlice';
import { UniversInfo } from '../../store/univers/universModels';
import { setSessionStorageItem } from '../../utils/helpers/sessionStorage';
import useTranslation from '../../utils/hooks/useTranslation';

const AddToCardButton: React.FC<{ article: Article; }> = ({ article }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.auth);
  const {
    listeSSO,
    univers,
  } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const {  articleReseau } = article;
  const disabledLogin = useAppSelector(isDisabledLogin);
  const isLoggedIn = user.active;
  const proSSOList = getProSSO(listeSSO);
  const disabled = disableArticleAddtoCartButton(article, user, listeSSO ?? [], !!disabledLogin);
  const { addToCart, queryState: { isLoading } } = useAddToCartHook();

  const onClickAddToCard = () => {
    let data = {
      article_uuid: article.uuid,
      reseau_uuid: articleReseau.reseau.uuid,
      univers_uuid: univers!.uuid, // NOSONAR
      quantite: 1,
      en_attente: false,
    };
    if (isLoggedIn) {
      addToCart(data);
    } else if (proSSOList?.length === 1) {

      setSessionStorageItem(SESSION_STORAGE_KEYS.ArticleAddToCard, JSON.stringify(data));
      //window.location.assign(proSSOList[0].url); // NOSONAR
      redirectToUrl(proSSOList[0].url + `?callback=${window.location.origin}/wso/callback`)
    }
  };
  return (
    <>
      <Button
      data-length="forcefit"
        primary
        disabled={disabled || isLoading}
        icon={showArticleCreditCardIcon(article, user) ? 'credit_card' : 'shopping_basket'}
        text="article.boutons.panier"
        textFirst={false}
        onClick={onClickAddToCard}
        title={t(
          isLoggedIn || disabledLogin ? 'article.boutons.hover_panier' : 'global.connexionPanel.boutonConnexion.titre'
        )}
      >
        {isLoading && <icon>
          <SvgLoader />
        </icon>}
      </Button>
      {/*<Button large wide
      data-text-align="left"
      disabled={disabled || (!isLoggedIn && (disabledLogin || proSSOList?.length !== 1))}
      title={t(isLoggedIn || disabledLogin ? 'vignettesArticle.buttons.ajoutPanier.titre' : 'global.connexionPanel.boutonConnexion.titre')}
      onClick={onClickAddToCard}
    >
      <Text>vignettesArticle.buttons.ajoutPanier.libelle</Text>
      {!isEnCompte && canOrder && articleReseau?.disponibilite && <icon>credit_card</icon>}
      <icon>shopping_basket</icon>
    </Button >
    */}
    </>
  );
};

export default AddToCardButton;
