import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import { formatNumberEuro, isArticleAvailable, isGroupSSO, renderPrice } from '../../project/helpers';
import { Article } from '../../store/article/articleModels';
import { useAppSelector } from '../../store/hooks';
import { UniversInfo } from '../../store/univers/universModels';

const ArticlePrices: React.FC<{ article: Article; isWidget?: boolean }> = ({ article, isWidget }) => { // NOSONAR
  const { listeSSO } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const isLoggedIn = useAppSelector((state) => state.auth.active);

  if (isLoggedIn || !isGroupSSO(listeSSO)) {
    const { articleReseau } = article;
    if (articleReseau && isArticleAvailable(article)) {
      const prixHt =
        (articleReseau.prix_ht as number) > 0 ? (
          <group data-align="center">
            <Text data-wrap="wrap" data-space="5" data-length="autofit">
              article.prix.professionnel
            </Text>
            <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large" data-user-select="text">
              {renderPrice(articleReseau.prix_ht ?? 0)}
            </Text>

            <Separator horizontal />
          </group>
        ) : (
          <></>
        );

      const prixHtRemise =
        (articleReseau.prix_ht_remise as number) > 0 && isLoggedIn ? (
          <group data-align="center">
            <Text data-wrap="wrap" data-space="5" data-length="autofit">
              article.prix.remise
            </Text>
            <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large" data-user-select="text">
              {formatNumberEuro(articleReseau.prix_ht_remise ?? 0)}
            </Text>

            <Separator horizontal />
          </group>
        ) : (
          <></>
        );

      const d3e =
        articleReseau.deee && articleReseau.deee > 0 ? (
          <group data-align="center">
            <Text data-wrap="wrap" data-space="5" data-length="autofit">
              article.prix.d3e
            </Text>
            <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">
              {formatNumberEuro(articleReseau.deee)}
            </Text>

            <Separator horizontal />
          </group>
        ) : (
          <></>
        );

      const prix_ttc =
        articleReseau.prix_ttc && articleReseau.prix_ttc > 0 ? (
          <group data-align="center">
            <Text data-wrap="wrap" data-space="5" data-length="autofit">
              article.prix.prix_ttc
            </Text>
            <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large" data-user-select="text">
              {formatNumberEuro(articleReseau.prix_ttc)}
            </Text>

            <Separator horizontal />
          </group>
        ) : (
          <></>
        );

      const eco =
        articleReseau.prix_ht && articleReseau.poids && articleReseau.pu_pmcb
          ? Math.max(articleReseau.poids * articleReseau.pu_pmcb * 1, 0.01)
          : 0;
      const prix_cont =
        eco > 0 ? (
          <group data-align="center">
            <Text data-wrap="wrap" data-space="5" data-length="autofit">
              article.prix.eco_contr
            </Text>
            <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">
              {formatNumberEuro(eco)}
            </Text>
            <Separator horizontal />
          </group>
        ) : (
          <></>
        );
      return (
        <group data-direction="column">
          {prix_ttc}
          {prixHt}
          {prixHtRemise}
          {d3e}
          {prix_cont}
        </group>
      );
    }
  }

  return <></>;
};
//return (

//  <>
//    {getArticlePriceHT(article, listeSSO, isLoggedIn) > 0 && <>
//      <Text data-wrap="wrap">vignettesArticle.prixPublicHT</Text>
//      <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">{formatNumberEuro(article?.articleReseau.prix_ht || 0)}</Text>
//    </>
//    }
//    {isAvailable && isLoggedIn && article.articleReseau.prix_ht_remise as number > 0 && <>
//      <Text>{(isLoggedIn && !isGroupSSO(listeSSO)) ? 'vignettesArticle.prixRemise' : 'vignettesArticle.prixProHT'}</Text>
//      <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">{formatNumberEuro(article?.articleReseau.prix_ht_remise || 0)}</Text>
//    </>
//    }
//    {isAvailable && isLoggedIn && article.articleReseau.deee > 0 && <>
//      <Text>vignettesArticle.prixEcoPart</Text>
//      <Text data-wrap="wrap" data-accent="" data-weight="700" data-text-size="large">{formatNumberEuro(article?.articleReseau.deee || 0)}</Text>
//    </>
//    }

//    {article.articleReseau ? <Text>{article.articleReseau.libelle_gescom}</Text> : ''}
//  </>

//);
//};

export default ArticlePrices;
