import { AutoComplete, Select as AntSelect, SelectProps as AntSelectProps, TreeSelectProps } from 'antd';
import { forwardRef, PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import Input, { InputProps, InputType } from './input';

type OptGroupType = { isGroup: true; text: string };

export type SelectProps = InputProps &
  Omit<AntSelectProps<any, Record<string | number, string>>, keyof InputProps | 'options'> & {
    optionRender?: React.FC<
      PropsWithChildren<{
        value?: string | number;
        text?: string | number;
        icon?: string;
        disabledState?: { disabled?: boolean };
      }>
    >;
    groupOptionRender?: React.FC<
      PropsWithChildren<{
        value?: string | number;
        text?: string | number;
        icon?: string;
        disabledState?: { disabled?: boolean };
      }>
    >;
    options: (string | number | Record<string | number, string | number> | OptGroupType)[];
    searchOptions?: {
      caseSensitive?: boolean;
      noAccent: boolean;
      contains: boolean;
    };
    allowSearch?: boolean;
    addEmptyOption?: boolean;
    autocomplete?: boolean;
    autoSelectSingleOption?: boolean;
  };

export const SelectInner: React.FC<SelectProps> = forwardRef<ReactElement, PropsWithChildren<SelectProps>>(
  (
    {
      options = [],
      autocomplete = false,
      searchOptions = { caseSensitive: false, contains: true, noAccent: true },
      allowSearch = false,
      allowClear = false,
      addEmptyOption = false,
      autoSelectSingleOption,
      fixLength,
      onClear,
      optionRender: OptionRender,
      groupOptionRender: GroupOptionRender,
      ...rest
    },
    ref
  ) => { // NOSONAR
    const [isMount, setIsMount] = useState(false);
    const { Option } = AntSelect;
    const formattedOptions = options.map((o: any) => {
      const isPlainObject = typeof o !== 'object';
      let value: string | number;
      let text: string | number;
      let icon: string = '';
      let disableState = {};

      if (isPlainObject) {
        value = text = o;
      } else if (o.isGroup) {
        text = o.text;
        value = '';
        disableState = { disabled: true };
      } else {
        const option: Record<string | number, string> = o;
        value = option.value;
        text = typeof option.text === 'undefined' ? value : option.text;
        icon = option.icon;
        disableState = { disabled: option.disabled };
      }
      if (autocomplete && !o.isGroup) {
        value = String(value);
        text = String(text);
      }
      return { value, text, disableState, icon, isGroup: o.isGroup };
    });

    const filterOption = (inputText: string, o?: number | string | Record<string | number, string>) => {
      let text: string;
      const isPlainObject = typeof o !== 'object';
      if (isPlainObject) {
        text = o?.toString() ?? '';
      } else {
        const option: Record<string | number, string> = o;
        text = option?.text || option?.value || '';
      }
      if (!searchOptions.caseSensitive) {
        text = text.toLowerCase();
      }

      let searchInput = searchOptions.caseSensitive ? inputText : inputText?.toLowerCase();
      if (searchOptions.noAccent) {
        text = text?.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        searchInput = searchInput?.normalize('NFD').replace(/[\u0300-\u036f]/g, '') || '';
      }
      return (
        !searchInput ||
        (text && (searchOptions.contains ? text.includes(searchInput) : text.startsWith(searchInput))) ||
        false
      );
    };
    let onClearValue = onClear;
    const { onChange, value } = rest;
    //if (allowClear && !onClearValue) {
    //    onClearValue = () => onChange && onChange('' as any);
    //}

    useEffect(() => {
      if (isMount && autoSelectSingleOption && formattedOptions.length === 1) {
        formattedOptions[0].value !== value && onChange && onChange(formattedOptions[0].value as any);
      }
    }, [formattedOptions, onChange, isMount, autoSelectSingleOption]); //eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      setIsMount(true);
    }, []);

    const emptyVal = rest.multiple ? [] : '';
    const Component = autocomplete ? AutoComplete : AntSelect;

    return (
      <Component
        ref={ref}
        filterOption={filterOption}
        showSearch={allowSearch}
        allowClear={allowClear && !!value}
        onClear={onClearValue}
        optionLabelProp={autocomplete ? '' : 'text'}
        onChange={(v) => {
          onChange?.(v === undefined ? emptyVal : v);
        }}
        {...(fixLength ? { fixlength: fixLength } : null)}
        {...(rest as any)}
      >
        {allowClear && (
          <Option value="" style={addEmptyOption ? null : { display: 'none' }}>
            {' '}
          </Option>
        )}
        {formattedOptions.map((o) => {
          const { value, text, icon, disableState, isGroup } = o;
          return (
            <Option key={`${value || text}`} value={value} text={text} {...disableState}>
              {isGroup && GroupOptionRender ? (
                <GroupOptionRender {...o} />
              ) : OptionRender ? ( // NOSONAR
                <OptionRender {...o} />
              ) : (
                <>
                  {icon ? (
                    <group data-wrap="no" data-gap="5" data-align="center">
                      <icon>
                        <img src={icon} alt="" />
                      </icon>
                      {text && <text data-ellipsis="">{text}</text>}
                    </group>
                  ) : (
                    text || ' '
                  )}
                </>
              )}
            </Option>
          );
        })}
      </Component>
    );
  }
);

const Select: React.FC<SelectProps> = forwardRef<ReactElement, PropsWithChildren<SelectProps>>((props, ref) => {
  return <Input ref={ref} {...props} type={InputType.Select} />;
});

export const TreeSelect: React.FC<InputProps & TreeSelectProps> = forwardRef<
  ReactElement,
  PropsWithChildren<InputProps & TreeSelectProps>
>((props, ref) => {
  return <Input ref={ref} {...props} type={InputType.TreeSelect} />;
});
export default Select;
