import classNames from 'classnames';
import { HTMLAttributes, ReactElement, useCallback, useRef } from 'react';
import useIntersectionObserver from '../../../../utils/hooks/useIntersectionObserver';
import Loader from '../../loader';
import { ListViewType } from '../useListHook';

export interface ListViewBlockItem {
  key?: PropertyKey;
  field?: string;
  label?: string;
  icon?: string;
  type?: 'field' | 'action' | 'badge';
  onClick?: (props: any) => void;
  render?: (props: any) => ReactElement;
}
export interface LazyLoadPropTypes {
  total?: number;
  loadMoreData: Function;
}

export type ListViewProps = HTMLAttributes<HTMLDivElement> & {
  dataSource?: Record<PropertyKey, any>[];
  getItemElement: (props: { data: any }) => React.ReactNode;
  view?: ListViewType;
  scrollerProps?: Record<string, any>;
  listProps?: Record<string, any>;
  lazyLoadProps?: LazyLoadPropTypes;
  header?: ReactElement;
  footer?: ReactElement;
} & (
    | {
        keyField: PropertyKey;
        getKey?: never;
      }
    | {
        keyField?: never;
        getKey: (data: any) => PropertyKey;
      }
  );

const ListView: React.FC<ListViewProps> = ({
  keyField,
  dataSource,
  getItemElement,
  view,
  scrollerProps,
  listProps,
  lazyLoadProps,
  getKey,
  header,
  footer,
  ...rest
}) => {
  const loaderRef = useRef(null);
  const handler = useCallback(
    (ratio: number) => ratio > 0 && lazyLoadProps?.loadMoreData(),
    [lazyLoadProps?.loadMoreData]
  );
  useIntersectionObserver(loaderRef, !!lazyLoadProps && handler, '1000px');

  return (
    <div data-view={view} {...rest} className={classNames('list_view_container', rest.className)}>
      <group className="list_view_scroller" {...scrollerProps}>
        <div className="list_view" {...listProps}>
          {header}
          {dataSource?.map((item) => (
            <div className="item" key={keyField ? item[keyField] : getKey!(item) // NOSONAR

            }>
              {getItemElement({ data: item })}
            </div>
          ))}
          {footer}
        </div>
        {(lazyLoadProps?.total ?? 0) > (dataSource?.length ?? 0) && (
          <>
            <space data-height="30"></space>
            <group data-space="20" data-contain="">
              <Loader ref={loaderRef} />
            </group>
          </>
        )}
      </group>
    </div>
  );
};

export default ListView;
