import { Link } from 'react-router-dom';
import { APP_URL } from '../project/defines';
import { getAppUrl } from '../project/helpers';
import { Product } from '../store/product/productModels';
import EmptyPicture from './emptyPicture';
import classNames from 'classnames';
import { group } from 'node:console';
import Separator from './shared/separator';

const ProductWidget: React.FC<{ product: Product; reseau_uuid?: string; noLink?: boolean; onClick?: () => void }> = ({
  product,
  reseau_uuid,
  noLink,
  onClick,
}) => {
  const productImage = product.image || product.familles?.find((f) => !!f.image)?.image;
  const content = (
    <>
      <group>
        {product?.commentaire && (
          <>
            <group data-gap="5" data-space="10" data-align="center">
              <text data-wrap="wrap" data-ellipsis="">
                {product.commentaire}
              </text>
            </group>
            <Separator horizontal data-margin="none" />
          </>
        )}
        {product?.reseau && (
          <>
            <group data-gap="5" data-space="10" data-align="center">
              <text data-wrap="wrap" data-ellipsis="">
                {product.reseau.nom}
              </text>
            </group>
            <Separator horizontal data-margin="none" />
          </>
        )}
      </group>
      <group data-ratio="1:1" data-space="10" data-shrink="no" data-position="center">
        <picture data-radius="5" data-object-fit="contain">
          {productImage ? <img src={productImage} alt="" /> : <EmptyPicture />}
        </picture>
      </group>
      <Separator horizontal data-margin="none" />
      <group data-gap="5" data-space="10" data-align="center">
        <text data-wrap="wrap" data-ellipsis="">
          [{product?.reference}]{product?.refcom2 ? '-[' + product?.refcom2 + ']' : ''}
        </text>
        <text data-wrap="wrap">{product?.libelle}</text>
      </group>
    </>
  );

  return (
    <group
      data-direction="column"
      data-border=""
      data-radius="10"
      onClick={onClick}
      className={classNames({ masked_family: product.is_masked })}
    >
      {noLink ? (
        content
      ) : (
        <Link
          data-type="group"
          data-direction="column"
          data-min-height="fit"
          to={getAppUrl(product.reseau?.uuid || reseau_uuid ? APP_URL.ProductReseau : APP_URL.Product, {
            routeParams: {
              id: product?.uuid || '',
              reseau_uuid: product.reseau?.uuid || reseau_uuid!, // NOSONAR
            },
          })}
        >
          {content}
        </Link>
      )}
    </group>
  );
};

export default ProductWidget;
