import { Tooltip } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { ColumnType } from 'antd/lib/table';
import Button from '../../components/shared/button';
import { PaginationToolbar } from '../../components/shared/list/paginationToolbar';
import Table from '../../components/shared/list/table';
import { ListViewType, useList } from '../../components/shared/list/useListHook';
import Separator from '../../components/shared/separator';
type TableRecord = Record<string, any>;
const columns: ColumnType<TableRecord>[] = [
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    key: 'description',
    title: 'Description',
    dataIndex: 'description',
    // width: 100,
    // ellipsis: true,
  },
  {
    key: 'age',
    title: 'Age',
    dataIndex: 'age',
    sorter: (a: TableRecord, b: TableRecord) => a.age - b.age,
    width: 60,
  },
  {
    key: 'address',
    title: 'Address',
    dataIndex: 'address',
    width: 300,
    filters: [
      {
        text: 'London',
        value: 'London',
      },
      {
        text: 'New York',
        value: 'New York',
      },
    ],
    onFilter: (value, record) => record.address.indexOf(value) === 0,
  },
  {
    title: 'Action',
    key: 'action',
    ellipsis: true,
    width: 50,
    render: () => (
      <group data-wrap="no">
        <Button adaptive icon="home" text="Download" />
      </group>
    ),
  },
  {
    title: '...',
    key: 'action1',
    ellipsis: true,
    //  fixed: 'right',
    width: 50,
    render: (value: string, record: Record<string, any>) => (
      <group data-wrap="no" data-gap="10">
        <Tooltip title={`Delete ${record.name}`}>
          <Button mini icon="delete" />
        </Tooltip>
        <Separator vertical />
        <Button mini>
          <icon data-icon-weight="300">more_vert</icon>
        </Button>
      </group>
    ),
  },
];
const data: TableRecord[] = [];

for (let i = 1; i <= 15; i++) {
  data.push({
    key: i,
    name: `John Brown ${i}`,
    age: Number(`${i}2`),
    address: `${i}720 Goodrich Ct Platteville, Colorado`,
    description: `John Brown, I am ${i}2 years old, living in ${i}720 Goodrich Ct Platteville, Colorado`,
  });
}

const TableDemo = () => {
  const hasData = true;
  const { pagingProps, sort, setSort } = useList({
    viewTypes: [ListViewType.Table],
  });
  const {
    pagination: { current, pageSize },
  } = pagingProps;
  return (
    <view data-space="30">
      {/*<div>{process.env.REACT_APP_VERSION}</div>*/}
      <group data-direction="column" data-gap="20">
        <group data-direction="column" data-wrap="no">
          <toolbar> Simple Table Markup </toolbar>
          <div className="table_view">
            <table>
              <thead>
                <tr>
                  <th>
                    <text>Sample Title</text>
                  </th>
                  <th>
                    <text>Another Title</text>
                  </th>
                  <th>
                    <text>Title</text>
                  </th>
                  <th>
                    <text>Sample Title</text>
                  </th>
                  <th>
                    <text>Another Title</text>
                  </th>
                  <th>
                    <text>Title</text>
                  </th>
                  <th data-action=""> </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <wrap>
                      <text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Sample Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <group data-justify="center">
                      <div data-effect="material" className="button micro">
                        <icon>delete</icon>
                      </div>
                    </group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <wrap>
                      <text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Sample Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <group data-justify="center">
                      <div data-effect="material" className="button micro">
                        <icon>delete</icon>
                      </div>
                    </group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <wrap>
                      <text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Sample Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <group data-justify="center">
                      <div data-effect="material" className="button micro">
                        <icon>delete</icon>
                      </div>
                    </group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <wrap>
                      <text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Sample Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <group data-justify="center">
                      <div data-effect="material" className="button micro">
                        <icon>delete</icon>
                      </div>
                    </group>
                  </td>
                </tr>
                <tr>
                  <td>
                    <wrap>
                      <text>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Sample Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Another Text</text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text>Text</text>
                    </wrap>
                  </td>
                  <td>
                    <group data-justify="center">
                      <div data-effect="material" className="button micro">
                        <icon>delete</icon>
                      </div>
                    </group>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </group>
        <group data-direction="column">
          <Table
            ellipsis={true}
            bordered={true}
            columns={columns}
            tableLayout={'auto'}
            showHeader={true}
            dataSource={
              hasData
                ? [...data]
                    .sort((a, b) => {
                      const field: string = (sort as any).field;
                      if (field) {
                        const coef = (sort as any).order === 'DESC' ? -1 : 1;
                        return coef * (a > b ? -1 : 1);
                      }
                      return 0;
                    })
                    .slice((current! - 1) * pageSize!, current! * pageSize!) // NOSONAR
                : []
            }
            pagination={false}
            setSorter={setSort}
            size={'small' as SizeType}
          />
          <PaginationToolbar {...pagingProps} total={hasData ? data.length : 0} />
        </group>
      </group>
    </view>
  );
};

export default TableDemo;
