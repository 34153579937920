import { ConfigProvider, notification, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import Loader from './components/shared/loader';
import AppRoutes from './pages/appRoutes';
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from './project/defines';
import { checkAccess, getApiUrl, getUniversFromUrl, loadResourceFromServer } from './project/helpers';
import { useGetLoggedUserQuery, useLazyIsConnectedQuery } from './store/auth/authApi';
import { reloadIsLoggedInFromStorage, setUserData } from './store/auth/authSlice';
import {
  addNotification,
  clearNotifications,
  logout,
  setDevisCount,
  setSatcMode,
  setSatcVisible,
  setSkinConfig,
} from './store/componentsSlice';
import { useGetActiveCountriesQuery, useGetCountryQuery } from './store/country/countryApi';
import { useGetDevisCountQuery } from './store/devis/devisApi';
import { useGetFamiliesQuery } from './store/family/familyApi';
import { useAddToCartHook, useAppDispatch, useAppSelector, useUpdateCartData } from './store/hooks';
import { useLazyGetTranslationsQuery } from './store/language/laguageApi';
import { setTranslations } from './store/language/languageSlice';
import { useGetCartItemsQuery } from './store/shoppingCart/shoppingCartApi';
import {
  currentCountryUuid,
  setActiveCountries,
  setCountries,
  //setCurrentCountryUuid,
  setCurrentCountry,
  setFamilies,
  setUnivers,
} from './store/sideData/siteDataSlice';
import { useGetUniversQuery } from './store/univers/universApi';
import { getCSSByPalette, getSkinConfigByUnivers } from './styles/skin';
import { getLocalStorageItem } from './utils/helpers/localStorage';
import { getSessionStorageItem, removeSessionStorageItem } from './utils/helpers/sessionStorage';
import { useAnalytics } from './project/analytics';
import useTranslation from './utils/hooks/useTranslation';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Popup from './components/shared/popup';
import { Text } from './components/shared/text';

const favicon = document.querySelector('[rel=icon]') as HTMLLinkElement;
const manifest = document.querySelector('[rel=manifest]') as HTMLLinkElement;

const AppDefault: React.FC = () => {
  const isLoggedIn = !!useAppSelector((state) => state.auth?.uuid);
  const userSocietteUuid = useAppSelector((state) => state.auth?.societe_uuid);
  const skinConfig = useAppSelector((state) => state.components.skinConfig);
  const translations = useAppSelector((state) => state.language.translations);
  const mode = useAppSelector((state) => state.components.mode);
  const [trigger] = useLazyGetTranslationsQuery();
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isAuthLoaded, setIsAuthLoaded] = useState(false);
  const [isLoginMessagePopupOpen, setIsLoginMessagePopupOpen] = useState(false);
  const [emailsFromParams, setEmailsFromParams] = useState('');
  const articleAddToCard = getSessionStorageItem(SESSION_STORAGE_KEYS.ArticleAddToCard);
  const previousURL = getSessionStorageItem(SESSION_STORAGE_KEYS.PreviousURL);

  const { t } = useTranslation();
  const notificationData = useAppSelector((state) => state.components.notification);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const isSatc = useAppSelector((state) => state.components.satcMode);
  const pays_uuid = useAppSelector(currentCountryUuid);
  const { data: activeCountries } = useGetActiveCountriesQuery(lang);
  const { data: countries } = useGetCountryQuery(lang);
  const { data: universData } = useGetUniversQuery(getUniversFromUrl());
  const [isConnected] = useLazyIsConnectedQuery();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emails = searchParams.get('emails');

  const { allowCallDataLayer, onDataLayer, analyticsState } = useAnalytics();

  const {
    data: authData,
    isError: isAuthError,
    isSuccess: isAuthSuccess,
  } = useGetLoggedUserQuery(
    { lang, univers_uuid: univers?.uuid ?? '' },
    { skip: !lang || !univers?.uuid || !activeCountries?.length }
  );

  const { data: devisCount } = useGetDevisCountQuery(
    { lang, univers_uuid: univers?.uuid, societe_uuid: userSocietteUuid },
    { skip: !univers?.uuid || !isLoggedIn }
  );

  const { data: cartData } = useGetCartItemsQuery(
    { lang, univers_uuid: univers?.uuid },
    { skip: !univers?.uuid || !!articleAddToCard || !isLoggedIn }
  );
  useUpdateCartData(cartData);

  const dispatch = useAppDispatch();
  const [notificationApi, contextHolder] = notification.useNotification();

  const { data: families } = useGetFamiliesQuery(
    {
      univers_uuid: univers?.uuid ?? '',
      lang,
      pays_uuid: pays_uuid ?? '',
      satc_mode: isSatc,
    },
    { skip: !univers?.uuid || !pays_uuid }
  );

  const { addToCart } = useAddToCartHook();

  useEffect(() => {
    const handler = () => {
      dispatch(reloadIsLoggedInFromStorage());
    };
    window.addEventListener('storage', handler);

    return () => {
      window.removeEventListener('storage', handler);
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (emails) {
      if (!isAuthLoaded) {
        return;
      }
      if (authData?.uuid) {
        setEmailsFromParams(emails);
        setIsLoginMessagePopupOpen(true);
      }
      navigate('/', { replace: true });
    }
  }, [emails, authData, isAuthLoaded, navigate]);

  useEffect(() => {
    let isConnectedInterval: any;
    if (isLoggedIn) {
      isConnectedInterval = setInterval(() => {
        (async () => {
          const response = await isConnected();
          if (!response.isSuccess) {
            dispatch(
              addNotification({
                message: t('global.notifications.deconnexionInactive.title'),
                description: t('global.notifications.deconnexionInactive.message'),
                type: 'success',
                duration: 5,
              })
            );
            clearInterval(isConnectedInterval);
            dispatch(logout());
            navigate('/', { replace: true });
          }
        })();
      }, 20000 * 1);
    }

    return () => {
      isConnectedInterval && clearInterval(isConnectedInterval);
    };
  }, [isLoggedIn, dispatch, isConnected, univers, lang]); // NOSONAR

  useEffect(() => {
    if (universData) {
      dispatch(setUnivers(universData));
    }
  }, [universData, dispatch]);

  useEffect(() => {
    if (isAuthError || isAuthSuccess) {
      setIsAuthLoaded(true);
    }
  }, [isAuthError, isAuthSuccess]);

  useEffect(() => {
    if (authData) {
      dispatch(setUserData(authData));
      //dispatch(setCurrentCountryUuid(authData.pays_uuid))
      if (activeCountries && activeCountries.length > 0) {
        dispatch(setCurrentCountry(activeCountries.find((item) => item.uuid === authData.pays_uuid)));
      }

      const isSatcVisible = checkAccess(authData, 'intranetsatc');
      dispatch(setSatcVisible(isSatcVisible));

      if (isSatcVisible && !getLocalStorageItem(LOCAL_STORAGE_KEYS.SatcMode)) {
        dispatch(setSatcMode(true));
      }
      if (articleAddToCard) {
        addToCart(JSON.parse(articleAddToCard));
        removeSessionStorageItem(SESSION_STORAGE_KEYS.ArticleAddToCard);
      }

      if (previousURL) {
        navigate(previousURL);
        removeSessionStorageItem(SESSION_STORAGE_KEYS.PreviousURL);
      }

      if (!analyticsState.isValidBrowser) {
        dispatch(
          addNotification({
            type: 'success',
            message: t('navigator_obselete.title'),
            description: t('navigator_obselete.message'),
            duration: 5,
          })
        );
      }

      const callDataLayer = async () => {
        (await allowCallDataLayer()) && onDataLayer();
      };
      callDataLayer();
    } else if (isAuthError) {
      dispatch(setCurrentCountry(activeCountries?.find((item) => item.code === 'FR')));
    }
  }, [authData, dispatch, isAuthError]); // eslint-disable-line

  useEffect(() => {
    if (devisCount) {
      dispatch(setDevisCount(devisCount));
    }
  }, [devisCount, dispatch]);

  useEffect(() => {
    if (families) {
      dispatch(setFamilies(families));
    }
  }, [families, dispatch]);

  useEffect(() => {
    if (activeCountries) {
      dispatch(setActiveCountries(activeCountries));
    }
  }, [activeCountries, dispatch]);

  useEffect(() => {
    if (countries) {
      dispatch(setCountries(countries));
    }
  }, [countries, dispatch]);

  useEffect(() => {
    if (notificationData.length) {
      notificationData.forEach((n) => {
        const { type, message, description, duration } = n;
        notificationApi[type]({ message, description, duration });
      });
      dispatch(clearNotifications());
    }
  }, [notificationData]); // eslint-disable-line

  const getTranslations = async () => {
    const result = await trigger();
    const obj = {};
    if (result?.data) {
      for (let transObj of result.data) {
        Object.assign(obj, { [transObj.code]: transObj.traduction });
      }
      dispatch(setTranslations(obj));
    }
  };


  useEffect(() => {
    getTranslations();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (mode === 'auto') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      setIsDarkMode(mediaQuery.matches);

      const handleChange = (event: MediaQueryListEvent) => {
        setIsDarkMode(event.matches);
      };

      mediaQuery.addEventListener('change', handleChange);

      return () => {
        mediaQuery.removeEventListener('change', handleChange);
      };
    } else {
      setIsDarkMode(mode === 'dark');
    }
  }, [mode]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  const {
    sizeConfig: { fontSize, borderRadius, fontFamily },
    colorPalette,
  } = skinConfig;

  const { colorPrimary } = colorPalette;

  useEffect(() => {
    if (univers?.uuid) {
      dispatch(setSkinConfig(getSkinConfigByUnivers(getUniversFromUrl())));
      document.title = univers.domain_name;

      const apiUrl = getApiUrl(getUniversFromUrl());
      loadResourceFromServer(`${apiUrl}/settings/favicon`, (data: any)=>{
        favicon.href = data;
      })
      loadResourceFromServer(`${apiUrl}/settings/manifest`, (data: any)=>{
        manifest.href = data;
      })
    }
  }, [dispatch, univers]);

  const algorithm = isDarkMode ? theme.darkAlgorithm : undefined;
  return (
    <>
      <style>{getCSSByPalette(colorPalette, fontFamily, fontSize as any)}</style>
      {univers?.slug === "sauter" && <style>:root { "{ --main-font: 'Arimo-Asap'; }" }</style>}
      {translations && univers?.uuid && families && isAuthLoaded ? (
        <ConfigProvider
          theme={{
            algorithm,
            token: {
              borderRadius,
              colorPrimary: colorPrimary,
              sizeStep: 5,
              sizeUnit: 3,
              fontSize,
              fontFamily,
              fontSizeLG: fontSize,
              paddingXXS: 5,
              marginXXS: 5,
              boxShadow: '0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)',
              boxShadowSecondary: '0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)',
              colorTextPlaceholder:''         
            },
          }}
        >
          <AppRoutes />
          {contextHolder}
          {isLoginMessagePopupOpen && (
            <Popup
              onClose={() => {
                setIsLoginMessagePopupOpen(false);
              }}
              title={t('global.loginMessage.title')}
            >
              <group data-direction="column" data-space="20" data-gap="10">
                <Text dataWrap="wrap" dataWeight="700">
                  global.loginMessage.body
                </Text>
                <Text dataWrap="wrap" accent translate="no">
                  {emailsFromParams?.split(',').join(', ')}
                </Text>
                <Text dataWrap="wrap">global.loginMessage.footer</Text>
              </group>
            </Popup>
          )}
        </ConfigProvider>
      ) : (
        <Loader />
      )}
    </>
  );
};

const AppRedirectDefault: React.FC = () => {
  const skinConfig = useAppSelector((state) => state.components.skinConfig);
  const mode = useAppSelector((state) => state.components.mode);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (mode === 'auto') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
      setIsDarkMode(mediaQuery.matches);

      const handleChange = (event: MediaQueryListEvent) => {
        setIsDarkMode(event.matches);
      };

      mediaQuery.addEventListener('change', handleChange);

      return () => {
        mediaQuery.removeEventListener('change', handleChange);
      };
    } else {
      setIsDarkMode(mode === 'dark');
    }
  }, [mode]);

  useEffect(() => {
    document.documentElement.classList.toggle('dark-mode', isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
      console.log(getUniversFromUrl());
      dispatch(setSkinConfig(getSkinConfigByUnivers(getUniversFromUrl())));
  }, [dispatch]);

  const {
    sizeConfig: { fontSize, borderRadius, fontFamily },
    colorPalette,
  } = skinConfig;

  const { colorPrimary } = colorPalette;
  const algorithm = isDarkMode ? theme.darkAlgorithm : undefined;
  return (
    <>
      <style>{getCSSByPalette(colorPalette, fontFamily, fontSize as any)}</style>
      <ConfigProvider
          theme={{
            algorithm,
            token: {
              borderRadius,
              colorPrimary: colorPrimary,
              sizeStep: 5,
              sizeUnit: 3,
              fontSize,
              fontFamily,
              fontSizeLG: fontSize,
              paddingXXS: 5,
              marginXXS: 5,
              boxShadow: '0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)',
              boxShadowSecondary: '0 0 15px 2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05)',
              colorTextPlaceholder:''         
            },
          }}
        >
          <AppRoutes />
    </ConfigProvider>
    </>
  )
}

const App: React.FC = () => {
  return <>
    {window.location.pathname === '/go' 
    || window.location.pathname === '/wso/callback'
    || window.location.pathname.startsWith('/connectas') ? <AppRedirectDefault/> : <AppDefault />}
  </>
}
export default App;
