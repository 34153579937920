import { Fragment, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import Checkbox from '../../../components/shared/inputs/checkbox';
import { Text } from '../../../components/shared/text';
import { WarrantyRequestSteps, WarrantyType } from '../../../project/defines';
import { ApiErrorHandler } from '../../../store/apiErrorHandler';
import { addNotification } from '../../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentCountryUuid } from '../../../store/sideData/siteDataSlice';
import { useCreateOrUpdateWarrantyRequestMutation } from '../../../store/warrantyRequest/warrantyRequestApi';
import { WarrantyRequest, WarrantyRequestUpdateModel } from '../../../store/warrantyRequest/warrantyRequestModels';
import useTranslation from '../../../utils/hooks/useTranslation';
import StepButtons from '../stepButtons';
import CGVLinkButton from './cgv';
import DeliveryForm from './deliveryForm';
import InstallerForm from './installerForm';
import WarrantyBasket from './warrantyBasket';
import Loader from '../../../components/shared/loader';

const Stage3: React.FC<{
  request: WarrantyRequest;
  setRequest: (request: WarrantyRequest) => void;
  changeStep: (step: string, back?: boolean) => void;
}> = ({ request, changeStep, setRequest }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const [createOrUpdateDemande, { isLoading }] = useCreateOrUpdateWarrantyRequestMutation();

  const [getDeliveryFormValues, setGetDeliveryFormValues] = useState<() => FieldValues>(() => () => ({} as any));
  const [isDeliveryFormValid, setIsDeliveryFormValid] = useState(false);
  const [getInstallerFormValues, setGetInstallerFormValues] = useState<() => FieldValues>(() => () => ({} as any));
  const [isInstallerFormValid, setIsInstallerFormValid] = useState(false);
  const [isCGVChecked, setIsCGVChecked] = useState(false);

  const { typedemande, sav_agree, garantie_type } = request;

  const isValidNextStep = () => {
    const areFormsValid = isDeliveryFormValid && isInstallerFormValid;
    if (typedemande === WarrantyType.Commande) {
      return isDeliveryFormValid && isInstallerFormValid && (isCGVChecked || garantie_type?.isAvg);
    }
    return areFormsValid;
  };

  const onClickNextStep = async (satcValidation?: boolean) => {
    if (isValidNextStep()) {
      const btnText = typedemande === WarrantyType.Commande && garantie_type?.isAvg ? 'commande' : 'valider';
      const btnAction = satcValidation ? 'satc' : btnText;

      const deliveryFormValues = getDeliveryFormValues();
      const installerFormValues = getInstallerFormValues();
      const data: WarrantyRequestUpdateModel = {
        uuid: request.uuid,
        etape: WarrantyRequestSteps.etape_end,
        univers_uuid: univers?.uuid ?? '',
        btnAction,
        pays_uuid: pays_uuid,
        ...deliveryFormValues,
        ...installerFormValues,
        client_uuid: installerFormValues?.client_uuid ? installerFormValues.client_uuid : null,
      };
      try {
        let result = await createOrUpdateDemande(data).unwrap();
        dispatch(
          addNotification({
            type: 'success',
            message: t(`global.notifications.demande.updateTitle`),
            description: t(`global.notifications.demande.updateMessage`),
            duration: 5,
          })
        );
        setRequest({ ...result, produit: request.produit });
      } catch (error: any) {
        ApiErrorHandler(error, dispatch, t, 'global.notifications.demande.demandeErrorTitle');
      }
    }
  };

  const infoParts = [
    { text: 'garantie.demande.etapes.3.info_cas1', include: true },
    { text: 'garantie.demande.etapes.3.info_cas1_2', include: sav_agree },
    { text: 'garantie.demande.etapes.3.info_cas1_3', include: true },
    { text: 'garantie.demande.etapes.3.info_cas1_4', include: sav_agree },
    { text: 'garantie.demande.etapes.3.info_cas1_5', include: true },
  ];

  const infoContent = (
    <group data-gap="10" data-direction="column">
      <text data-wrap="wrap" data-space="20">
        {typedemande === WarrantyType.Commande
          ? infoParts
            .filter((ip) => ip.include)
            .map((ip) => t(ip.text).split('<br>'))
            .flat()
            .map((text, i) => <Fragment key={'text' + i} // NOSONAR
            >{!!text && <Text data-wrap="wrap">{text}</Text>}</Fragment>)
          : 'info_cas2'}
      </text>

    </group>
  );
  return (
    <section data-space="5">
      {isLoading && <Loader />}
      <wrapper>
        <space data-height="30"></space>
        <view data-vertical="" data-border="none" data-adaptive="1300" data-test="" data-gap="40">
          <DeliveryForm
            request={request}
            setGetFormValues={setGetDeliveryFormValues}
            setIsFormValid={setIsDeliveryFormValid}
          />
          <InstallerForm
            request={request}
            setGetFormValues={setGetInstallerFormValues}
            setIsFormValid={setIsInstallerFormValid}
          />
          <view data-border="none" data-scroll="" data-space="5" data-auto-height="">
            <group data-direction="column" data-gap="20">
              <WarrantyBasket request={request} />
              {infoContent}
              {typedemande === WarrantyType.Commande && !garantie_type?.isAvg && (
                <group data-align="center" data-width="auto" data-space="10">
                  <Checkbox
                    value={isCGVChecked as any}
                    onChange={(v) => {
                      setIsCGVChecked(v.target.checked);
                    }}
                    classic
                    label="garantie.demande.etapes.3.form.coordonnees.cgvLabel"
                  />
                  <CGVLinkButton request={request} />
                </group>
              )}
            </group>
          </view>
        </view>
        <StepButtons
          request={request}
          disableRight={!isValidNextStep()}
          disableSatc={!isValidNextStep()}
          onClickLeft={() => changeStep(WarrantyRequestSteps.etape2, true)}
          onClickRight={onClickNextStep}
          onClickSatc={() => onClickNextStep(true)}
        />
      </wrapper>
    </section>
  );
};

export default Stage3;
