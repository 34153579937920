import classNames from 'classnames';
import { forwardRef, PropsWithChildren, ReactElement } from 'react';
import { Text } from '../text';
import Input, { InputProps, InputType } from './input';

export type CheckboxProps = InputProps & {
  classic?: boolean;
  noInk?: boolean;
  checkbox?: boolean;
  simple?: boolean;
};

export const CheckboxInner: React.FC<CheckboxProps> = forwardRef<ReactElement, PropsWithChildren<CheckboxProps>>(
  (
    {
      children,
      classic,
      noInk,
      checkbox,
      minimal,
      simple,
      label,
      className,
      value,
      isDirty,
      isTouched,
      invalid,
      error,
      ...inputProps
    },
    ref
  ) => {
    let type: any;
    let input;
    input = <input type="checkbox" ref={ref as any} checked={!!value} {...inputProps} />; // NOSONAR

    if (classic) {
      type = { classic: '' };
    } else {
      type = { checkbox: '' };
      if (minimal) {
        type['data-minimal'] = '';
      }
      if (simple) {
        type.simple = '';
      }
    }

    let content = (
      <label
        {...type}
        className={classNames(className, {
          error: invalid,
          touched: isTouched,
          dirty: isDirty,
        })}
      >
        {input}
        <box>
          <check></check>
          {classic && !noInk && <ink></ink>}
        </box>
        <Text>{label}</Text>
      </label>
    );

    return content;
  }
);

const Checkbox: React.FC<CheckboxProps> = forwardRef<ReactElement, PropsWithChildren<CheckboxProps>>((props, ref) => {
  return <Input ref={ref} {...props} type={InputType.Checkbox} />;
});

export default Checkbox;
