import FileAccederInformationsTechniques from '../assets/help/atlantic/acceder_a_la_documentation_technique.pdf';
import FileCommanderPieceDetachee from '../assets/help/atlantic/commander_une_piece_de_rechange.pdf';
import FileCreerAccesCollaborateur from '../assets/help/atlantic/creer_un_acces_collaborateur.pdf';
import FileFaireDemandeGarantie from '../assets/help/atlantic/faire_une_demande_de_garantie.pdf';
import FileObtenirUnDevis from '../assets/help/atlantic/obtenir_un_devis.pdf';
import FileTrouverProduitConcerne from '../assets/help/atlantic/trouver_le_produit_concerne.pdf'; //+
import EspaceSavFileAccederInformationsTechniques from '../assets/help/espace-sav/acceder_a_la_documentation_technique.pdf';
import EspaceSavFileCommanderPieceDetachee from '../assets/help/espace-sav/commander_une_piece_de_rechange.pdf';
import EspaceSavFileCreerAccesCollaborateur from '../assets/help/espace-sav/creer_un_acces_collaborateur.pdf';
import EspaceSavFileFaireDemandeGarantie from '../assets/help/espace-sav/faire_une_demande_de_garantie.pdf';
import EspaceSavFileTrouverProduitConcerne from '../assets/help/espace-sav/trouver_le_produit_concerne.pdf'; //+
import SauterFileAccederInformationsTechniques from '../assets/help/sauter/acceder_aux_informations_techniques.pdf';
import SauterFileCommanderPieceDetachee from '../assets/help/sauter/commander_une_piece_detachée.pdf';
import SauterFileCreerAccesCollaborateur from '../assets/help/sauter/creer_un_acces_collaborateur.pdf';
import SauterFileTrouverProduitConcerne from '../assets/help/sauter/trouver_le_produit_concerne.pdf'; //+

export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULT_COUNTRY = 'FR';
export const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_ARRAY = ['DD/MM/YYYY', 'DD/MM/YY', 'DD-MM-YYYY', 'DD-MM-YY'];
export const DEFAULT_DATE_FORMAT_WITH_TIME = 'DD/MM/YYYY HH:mm';
export const DEFAULT_COUNTRY_LANGUAGE_SEPARATOR = '_';

export const LOCAL_STORAGE_KEYS = {
  Auth: 'Authentication',
  IsLoggedIn: 'IsLoggedIn',
  Language: 'LANGUAGE',
  Mode: 'MODE',
  SatcMode: 'SATC_MODE',
  SatcVisible: 'SATC_VISIBLE',
  CurrentCountry: 'CURRENTCOUNTRY',
  AppToken: 'APP_TOKEN',
  UserToken: 'USER_TOKEN',
  RefreshToken: 'REFRESH_TOKEN',
  SessionCode: 'SESSION_CODE',
  LogoutUrl: 'LOGOUT_URL',
  Country: 'COUNTRY',
};

export const SESSION_STORAGE_KEYS = {
  PreviousURL: 'PREVIOUS_URL',
  ArticleAddToCard: 'ARTICLE_ADD_TO_CARD',
  ArticleReplace: 'ARTICLE_REPLACE',
};

export enum SLUG_ACCESS_KEYS {
  Commande = 'commande',
  Sav = 'sav',
  SeeDiscountedPrice = 'voir-les-prix-remisés',
  BankCardPayment = 'paiement-cb',
  GestionCollaborateurs = 'gestion-collaborateurs',
  StationWeb = 'station-web',
}

export const APP_URL = {
  Base: '/:lang',
  HomePage: '',
  Template: 'template',
  ShoppingCart: 'panier',
  ShoppingCartSuccess: 'panier/:result_status',
  WarrantyHistory: 'garantie/historique',
  WarrantyReplacement: 'garantie/demandes/:reference?', // modify this route if something
  OrderHistory: 'commandes/historique',
  OrderDetails: 'commandes/:id',
  QuoteHistory: 'devis/historique',
  Profile: 'profil',
  Societe: 'societe',
  Clients: 'clients',
  Login: 'login',
  WsoCallback: 'wso/callback',
  ConnectAs: 'connectas/:code',
  RedirectResource: 'go',
  Search: 'recherche/:keywords?',
  Family: 'familles/:id',
  FamilyDetail: 'familles/:id/detail/:tabname',
  Product: 'produits/:id',
  ProductSchema: 'produits/:id/schema_uuid/:schema_uuid',
  ProductReseau: 'produits/:id/reseaux/:reseau_uuid',
  Article: 'articles/:id',
  ArticleReseau: 'articles/:id/reseaux/:reseau_uuid',
  //Article: 'articles/:id',
};

export const UNIVERS = {
  Pacific: 'pacific',
  Atlantic: 'atlantic',
  Thermor: 'thermor',
  AtlanticFR: 'atlantic-fr',
  ThermorFR: 'thermor-fr',
  Acv: 'acv',
  AcvFR: 'acv-fr',
  AutresMarques: 'autres-marques',
  EquationWelcome: 'equation-welcome',
  Oem: 'oem',
  Sauter: 'sauter',
  Ygnis: 'ygnis',
  EspaceSav: 'espace-sav',
  Other: 'other'
};

export const OTHER_WEBSITES = { StationWeb: 'station-web' };

export const OrderStatus = {
  Creee: 'creee',
  Attente: 'attente',
  AttenteSATC: 'attente-satc',
  AttenteBanque: 'attente-banque',
  AttentePaiement: 'attente-paiement',
  Soldee: 'soldee',
  Expediee: 'expediee',
  Validee: 'validee',
  RefuseePrestatairePaiement: 'refusee-prestataire-paiement',
  Annulee: 'annulee',
};

export const WarrantyType = {
  Commande: 'commande',
  Retour: 'retour',
};

export const WarrantyRequestSteps = {
  etape1: 'etape_1',
  etape2: 'etape_2',
  etape3: 'etape_3',
  etape_end: 'etape_end',
};

export const WarrantyRequestSatcStatus = {
  PasDemande: 'non-demandee',
  Attente: 'en-cours',
  Valide: 'acceptee',
  Refuse: 'refusee',
};

export const WarrantyRequestValideStatus = {
  EnCours: 'en-cours',
  Valide: 'validee',
  Exporte: 'expediee',
  Transmise: 'transmise',
  Acceptee: 'acceptee',
  Refusee: 'refusee',
  Partiel: 'partielle',
  NonRecevable: 'non-recevable',
};

export const WarrantyItemTypes = {
  Product: 'garantie_produit',
  Article: 'garantie_piece',
};

export const WarrantyStage2FormFields = {
  date_mise_en_service: 'date_mise_en_service',
  defaut_uuid: 'defaut_uuid',
  no_serie: 'no_serie',
  commentaire: 'commentaire',
  dossier: 'dossier',
  renvoie_produit: 'renvoie_produit',
  renvoie_piece: 'renvoie_piece',
};

export const WarrantyStage2FormFieldsByType = {
  [WarrantyItemTypes.Product]: [
    WarrantyStage2FormFields.date_mise_en_service,
    WarrantyStage2FormFields.defaut_uuid,
    WarrantyStage2FormFields.no_serie,
    WarrantyStage2FormFields.commentaire,
  ],
  [WarrantyItemTypes.Article]: [WarrantyStage2FormFields.dossier],
};

export const WarrantyDeliveryFormFields = {
  reference_commande: 'reference_commande',
  livraison_nom: 'livraison_nom',
  livraison_adresse1: 'livraison_adresse1',
  livraison_adresse2: 'livraison_adresse2',
  livraison_code_postal: 'livraison_code_postal',
  livraison_ville: 'livraison_ville',
  email: 'email',
  livraison_pays: 'livraison_pays',
};

export const WarrantyInstallerFormFields = {
  client_uuid: 'client_uuid',
  no_bon_retour_client: 'no_bon_retour_client',
};

export type HelpItemModel = {
  type: string;
  id?: number;
  file?: any;
};
export type HelpModel = Record<string, Record<string, HelpItemModel>>;

export const helpItems: HelpModel = {
  atlantic: {
    video_commandepiece: {
      type: 'vimeo',
      id: 343451354,
    },
    video_demandegarantie: {
      type: 'vimeo',
      id: 343451375,
    },
    video_informationstechniques: {
      type: 'vimeo',
      id: 343451333,
    },
    // nouvelEspaceSav: {
    //   type: 'link',
    //   file: FileNouvelEspaceSAV
    // },
    trouverProduitConcerne: {
      //+
      type: 'link',
      file: FileTrouverProduitConcerne,
    },
    accederInfoTechniques: {
      //+
      type: 'link',
      file: FileAccederInformationsTechniques,
    },
    commanderPieceDetachee: {
      //+
      type: 'link',
      file: FileCommanderPieceDetachee,
    },
    faireDemandeGarantie: {
      //+
      type: 'link',
      file: FileFaireDemandeGarantie,
    },
    creerAccesCollab: {
      //+
      type: 'link',
      file: FileCreerAccesCollaborateur,
    },
    obtenirUnDevis: {
      //+
      type: 'link',
      file: FileObtenirUnDevis,
    },
    // demanderAccesConnecte: {
    //   type: 'link',
    //   file: FileDemanderAccesConnecte
    // },
    // retrouverIdentifiantEtMotDePasse: {
    //   type: 'link',
    //   file: FileRetrouverIdentifiantEtMotDePasse
    // },
    // installerchromefirefox: {
    //   type: 'link',
    //   file: FileInstallationChromeOuFireFox
    // },
    // doublonemail: {
    //   type: 'link',
    //   file: FileDoubonEmail
    // }
  },
  'espace-sav': {
    video_commandepiece: {
      type: 'vimeo',
      id: 450134848,
    },
    // nouvelEspaceSav: {
    //   type: 'link',
    //   file: EspaceSavFileNouvelEspaceSAV
    // },
    trouverProduitConcerne: {
      //+
      type: 'link',
      file: EspaceSavFileTrouverProduitConcerne,
    },
    accederInfoTechniques: {
      //+
      type: 'link',
      file: EspaceSavFileAccederInformationsTechniques,
    },
    commanderPieceDetachee: {
      //+
      type: 'link',
      file: EspaceSavFileCommanderPieceDetachee,
    },
    faireDemandeGarantie: {
      //+
      type: 'link',
      file: EspaceSavFileFaireDemandeGarantie,
    },
    creerAccesCollab: {
      //+
      type: 'link',
      file: EspaceSavFileCreerAccesCollaborateur,
    },
    // installerchromefirefox: {
    //   type: 'link',
    //   file: EspaceSavFileInstallationChromeOuFireFox
    // },
    // retrouverIdentifiantEtMotDePasse: {
    //   type: 'link',
    //   file: EspaceSavFileRetrouverIdentifiantEtMotDePasse
    // },
    // reinitialiser_mot_de_passe:{
    //   type: 'link',
    //   file: EspaceSavFileReinitialiseMotDePasse
    // }
  },
  thermor: {
    video_commandepiece: {
      type: 'vimeo',
      id: 450134848,
    },
    // nouvelEspaceSav: {
    //   type: 'link',
    //   file: EspaceSavFileNouvelEspaceSAV
    // },
    trouverProduitConcerne: {
      //+
      type: 'link',
      file: EspaceSavFileTrouverProduitConcerne,
    },
    accederInfoTechniques: {
      //+
      type: 'link',
      file: EspaceSavFileAccederInformationsTechniques,
    },
    commanderPieceDetachee: {
      //+
      type: 'link',
      file: EspaceSavFileCommanderPieceDetachee,
    },
    creerAccesCollab: {
      //+
      type: 'link',
      file: EspaceSavFileCreerAccesCollaborateur,
    },
    // installerchromefirefox: {
    //   type: 'link',
    //   file: EspaceSavFileInstallationChromeOuFireFox
    // },
    // retrouverIdentifiantEtMotDePasse: {
    //   type: 'link',
    //   file: EspaceSavFileRetrouverIdentifiantEtMotDePasse
    // },
    // reinitialiser_mot_de_passe:{
    //   type: 'link',
    //   file: EspaceSavFileReinitialiseMotDePasse
    // }
  },
  sauter: {
    video_commandepiece: {
      type: 'vimeo',
      id: 450142701,
    },
    // nouvelEspaceSav: {
    //   type: 'link',
    //   file: SauterFileNouvelEspaceSAV
    // },
    trouverProduitConcerne: {
      //+
      type: 'link',
      file: SauterFileTrouverProduitConcerne,
    },
    accederInfoTechniques: {
      //+
      type: 'link',
      file: SauterFileAccederInformationsTechniques,
    },
    commanderPieceDetachee: {
      //+
      type: 'link',
      file: SauterFileCommanderPieceDetachee,
    },
    creerAccesCollab: {
      //+
      type: 'link',
      file: SauterFileCreerAccesCollaborateur,
    },
    // installerchromefirefox: {
    //   type: 'link',
    //   file: SauterFileInstallationChromeOuFireFox
    // },
    // retrouverIdentifiantEtMotDePasse: {
    //   type: 'link',
    //   file: SauterFileRetrouverIdentifiantEtMotDePasse
    // },
    // reinitialiser_mot_de_passe:{
    //   type: 'link',
    //   file: SauterFileReinitialiseMotDePasse
    // }
  },
};
