import useTranslation from '../../utils/hooks/useTranslation';
import { SatcFamillesModel } from '../../store/satc/satcModels';
import { Text } from '../../components/shared/text';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { APP_URL } from '../../project/defines';
import { getAppUrl } from '../../project/helpers';
import Separator from '../../components/shared/separator';

const TabGeneral: React.FC<{ data: SatcFamillesModel }> = ({ data }) => {
  const { general } = data;
  const { t } = useTranslation();

  const productHeaders = useMemo(() => { // NOSONAR
    let headers_produits: any = null;
    if (general?.produits && general.produits.length > 0) {
      general.produits.forEach((produit: any) => {
        if (produit.parametres) {
          produit.parametres.forEach((parametre: any) => {
            if (parametre.valeur_par_defaut && parametre.valeur_par_defaut !== '') {
              if (!headers_produits) headers_produits = {};
              headers_produits[parametre.parametre.uuid] = parametre.parametre.libelle;
            }
          });
        }
      });
    }
    return headers_produits;
  }, [general]);

  return (
    <group data-space="1" data-space-vertical="20" data-gap="20">
      <group
        direction="row"
        data-gap="10"
        data-type="grid"
        data-grid-size="330"
        data-align="start"
      >
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>
                  {t("satc.detail.tabs.famille.infoGenerales.libelle")}
                </th>
              </tr>
            </thead>
            <tbody>
              {!!general.infos.length &&
                general.infos?.map((info: any) => {
                  return (
                    <tr key={info.uuid}>
                      <td>
                        <wrap>
                          <text data-wrap="wrap" data-user-select="text">{info.titre}</text>
                        </wrap>
                      </td>
                      <td>
                        <wrap>
                          <text data-wrap="wrap" data-user-select="text">{info.info}</text>
                        </wrap>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>
                  {t(`satc.detail.tabs.famille.garanties.libelle`)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <wrap>
                    <text data-wrap="wrap">
                      {t("satc.detail.tabs.famille.garanties.first")}
                    </text>
                  </wrap>
                </td>
                <td>
                  <wrap>
                    <text data-wrap="wrap">
                      {general?.garanties?.procedure_garantie_generique ?? ""}
                    </text>
                  </wrap>
                </td>
              </tr>
              {general.garanties.procedure_garantie_exceptionnelle && (
                <tr>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {t("satc.detail.tabs.famille.garanties.second")}
                      </text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {general?.garanties
                          ?.procedure_garantie_exceptionnelle ?? ""}
                      </text>
                    </wrap>
                  </td>
                </tr>
              )}
              {general.garanties.montant_main_oeuvre && (
                <tr>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {t("satc.detail.tabs.famille.garanties.third")}
                      </text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {general?.garanties?.montant_main_oeuvre}
                      </text>
                    </wrap>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th colSpan={2}>
                  {t(`satc.detail.tabs.famille.commercialisation.libelle`)}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <wrap>
                    <text data-wrap="wrap">
                      {t("satc.detail.tabs.famille.commercialisation.first")}
                    </text>
                  </wrap>
                </td>
                <td>
                  <wrap>
                    <text data-wrap="wrap">
                      {general?.commercialisation?.debut ?? ""}
                    </text>
                  </wrap>
                </td>
              </tr>
              {general.commercialisation.fin && (
                <tr>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {t("satc.detail.tabs.famille.commercialisation.second")}
                      </text>
                    </wrap>
                  </td>
                  <td>
                    <wrap>
                      <text data-wrap="wrap">
                        {general?.commercialisation.fin ?? ""}
                      </text>
                    </wrap>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div className="table_view">
          <table>
            <thead>
              <tr>
                <th>{t(`satc.detail.tabs.famille.referent`)}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <wrap>
                    <text data-wrap="wrap">
                      {general.referent
                        ? general.referent
                        : t("satc.detail.tabs.famille.referent_novalue")}
                    </text>
                  </wrap>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </group>
      <Separator horizontal />
      <group data-gap="10">
        <group
          direction="row"
          data-gap="10"
          data-border=""
          data-space="10"
          data-radius="5"
          data-align="center"
        >
          <Text data-space-horizontal="5">satc.detail.tabs.famille.normes</Text>
          {!!data?.general?.normes?.length &&
            general.normes.map((norme: any) => {
              return (
                <Tooltip key={norme.uuid} title={norme.libelle}>
                  <group data-width="auto" data-radius="5" data-space="5" data-border="">
                    <picture data-object-fit=""  data-ratio="1:1" data-length="40">
                      <img src={norme.logo} alt={norme.libelle} />
                    </picture>
                  </group>

                </Tooltip>
              );
            })}
        </group>

        <group data-wrap="no" data-adaptive="800" data-gap="10">
          <group data-align="start" data-gap="10" data-width="auto">
            <div className="table_view" data-min-length="300">
              <table>
                <thead>
                  <tr>
                    <th>{t("satc.detail.tabs.famille.produits.ref")}</th>
                    <th>
                      {t("satc.detail.tabs.famille.produits.designation")}
                    </th>
                    {!!productHeaders &&
                      Object.keys(productHeaders).map((uuid) => {
                        return <th key={uuid}>{productHeaders[uuid]}</th>;
                      })}
                  </tr>
                </thead>
                <tbody>
                  {general &&
                    general.produits.length > 0 &&
                    general.produits.map((product: any) => {
                      return (
                        <tr key={product.uuid}>
                          <td>
                            <wrap>
                              <Link
                                data-type="link"
                                to={getAppUrl(
                                  product.reseau?.uuid
                                    ? APP_URL.ProductReseau
                                    : APP_URL.Product,
                                  {
                                    routeParams: {
                                      id: product?.uuid || "",
                                      reseau_uuid: product.reseau?.uuid,
                                    },
                                  }
                                )}
                              >
                                {product.reference}
                              </Link>
                            </wrap>
                          </td>
                          <td>
                            <wrap>
                              <text data-wrap="wrap">{product.libelle}</text>
                            </wrap>
                          </td>
                          {!!productHeaders &&
                            Object.keys(productHeaders).map((uuid) => {
                              let parametre_produit = product.parametres.filter(
                                (parametre: any) =>
                                  parametre.parametre.uuid === uuid
                              )[0],
                                value = null;

                              if (parametre_produit)
                                value = parametre_produit.valeur_par_defaut;

                              return (
                                <td key={uuid}>
                                  <wrap>
                                    <text data-wrap="wrap">{value}</text>
                                  </wrap>
                                </td>
                              );
                            })}
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </group>
          {!!general?.images?.length && (
            <group
              data-contain=""
              data-border=""
              data-radius="5"
              data-space="5"
              data-min-length="300"
            >
              <picture data-object-fit="contain">
                <img src={general?.images[0]} alt="" />
              </picture>
            </group>
          )}
        </group>


      </group>
    </group>
  );
};

export default TabGeneral;
