import classNames from 'classnames';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { createPortal } from 'react-dom';
import isEqual from 'lodash.isequal';
import useOnClickOutside from './outsideClickHandler';
import { calculatePositionUpToDirection } from '../helpers';

export const applicationRoot: Element = document.getElementById('context-menu-root') as Element;

export interface Position {
  top: number;
  left: number;
}
const defaultPosition: Position = { top: 0, left: 0 };

export type ContextMenuDirections =
  | 'left'
  | 'top'
  | 'right'
  | 'bottom'
  | 'topLeft'
  | 'topRight'
  | 'bottomLeft'
  | 'bottomRight'
  | 'bottomForce';

const useOpener = (
  direction?: ContextMenuDirections,
  isSticky?: boolean,
  adaptive = false,
  useOpenerWidth = false,
  needHighZIndex = false
) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [position, setPosition] = useState(defaultPosition);
  const [, start] = useTransition();

  const refOpener = useRef<HTMLElement>(null);
  const refWrapper = useRef<HTMLDivElement>(null);

  useOnClickOutside(refWrapper, () => setIsOpen(false), refOpener);

  const toggle = () => {
    if (!isOpen) {
      setPosition(defaultPosition);
    }
    setIsOpen(!isOpen);
  };
  const close = () => {
    setPosition(defaultPosition);
    setIsOpen(false);
  };
  const open = () => {
    setIsOpen(true);
  };

  const calculatePosition = useCallback(() => {
    if (direction) {
      if (refOpener.current && refWrapper.current) {
        const { top, left } = calculatePositionUpToDirection(direction, refOpener, refWrapper, isFixed, isSticky);
        start(() => {
          setPosition({ left, top });
        });
      }
    }
  }, [refOpener, refWrapper, direction, isOpen, isFixed, isSticky]);

  useEffect(() => {
    calculatePosition();
  }, [calculatePosition]);

  useEffect(() => {
    if (isOpen && isSticky) {
      const onScroll = () => {
        const top = refOpener.current?.getBoundingClientRect().top;
        setIsFixed(Math.round(top!) === 0); // NOSONAR
      };

      window.addEventListener('scroll', onScroll);
      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }
  }, [isOpen, isSticky]);

  useEffect(() => {
    if (refWrapper.current) {
      const element = refOpener.current;
      refOpener.current?.classList[isOpen ? 'add' : 'remove']('selected');
      return () => {
        element?.classList.remove('selected');
      };
    }
  }, [isOpen, refOpener]);

  const calculatedOpenerWidth = useOpenerWidth && refOpener.current?.getBoundingClientRect().width;
  let wrapperStyle: CSSProperties | undefined = useMemo(() => {
    if (direction) {
      let result: any = {
        transform: `translate(${position.left}px, ${position.top}px)`,
        position: isFixed ? 'fixed' : undefined,
        // zIndex: isFixed ? '1000' : '100',
        zIndex: needHighZIndex ? '1000' : '100',
      };
      if (calculatedOpenerWidth) {
        result.width = calculatedOpenerWidth;
      }
      return result;
    }
  }, [position, direction, isFixed, calculatedOpenerWidth]);

  useEffect(() => {
    if (direction) {
      const onResize = () => {
        setIsOpen(false);
        setPosition(defaultPosition);
        setIsFixed(false);
      };
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, [direction]);

  const active = !direction || !isEqual(position, defaultPosition);

  const Wrapper: React.FC<React.PropsWithChildren> = useMemo(() => {
    return ({ children }: any) => {
      let content = (
        <div
          className={classNames('context_menu', {
            open: isOpen,
            active: active,
            relative: direction,
            adaptive,
          })}
          style={wrapperStyle}
        >
          {children}
        </div>
      );

      content = direction ? createPortal(content, applicationRoot) : content;

      return isOpen ? content : <></>;
    };
  }, [direction, wrapperStyle, isOpen, active]);

  return { toggle, open, close, Wrapper, isOpen, refOpener, refWrapper };
};

export default useOpener;
