import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BreadcrumbWidget from '../../components/breadcrumbWidget';
import FamilyWidget from '../../components/familyWidget';
import ProductWidget from '../../components/productWidget';
import ListView from '../../components/shared/list/listView';
import { ListViewType } from '../../components/shared/list/useListHook';
import Loader from '../../components/shared/loader';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import SupportWidget, { SupportWidgetType } from '../../components/support';
import { APP_URL } from '../../project/defines';
import { getAppUrl } from '../../project/helpers';
import { useGetFamilyQuery } from '../../store/family/familyApi';
import { Family as _Family } from '../../store/family/familyModels';
import { useAppSelector } from '../../store/hooks';
import { Product } from '../../store/product/productModels';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import SatcFamilyDetail from './satcFamilyDetail';

const Family: React.FC = () => { // NOSONAR
  const { id, tabname } = useParams();
  const authUser = useAppSelector((state) => state.auth);
  const isSatcVisible = useAppSelector((state) => state.components.satcVisible) && !!authUser?.uuid;
  const isSatc = useAppSelector((state) => state.components.satcMode);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const navigate = useNavigate();

  const {
    data: familyData,
    isLoading: isFamilyLoading,
    isFetching: isFamilyFetching,
  } = useGetFamilyQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id, satc_mode: isSatc },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang }
  );
  const isLoading = isFamilyFetching || isFamilyLoading;

  useEffect(() => {
    if (!familyData) {
      return;
    }
    if (!isSatcVisible || !isSatc || !familyData?.last_level) {
      navigate(
        getAppUrl(APP_URL.Family, {
          routeParams: {
            id: id ?? '',
          },
        }),
        { replace: true }
      );
    }
  }, [tabname, isSatc, isSatcVisible, id, navigate, familyData]);

  return isLoading ? (
    <group data-space="30" data-justify="center">
      <Loader />
    </group>
  ) : isSatcVisible && isSatc && familyData?.last_level ? ( // NOSONAR
    <SatcFamilyDetail />
  ) : (
    <section>
      <wrapper>
        <BreadcrumbWidget />
        <SupportWidget type={SupportWidgetType.Family} />
        <Separator horizontal data-margin="30" />

        {(familyData?.children?.length as number) > 0 && (
          <group data-direction="column" data-fit="1">
            <group data-gap="10" data-align="center" data-space="10">
              <Text data-accent="" data-text-size="large" data-wrap="true" data-weight="700">
                famille.titres.familles
              </Text>
            </group>
            <group data-align="center">
              <ListView
                keyField="uuid"
                data-template="170"
                view={ListViewType.Block}
                //  scrollerProps={{ "data-space": 10 }}
                listProps={{ 'data-gap': 15 }}
                dataSource={familyData?.children ?? []}
                getItemElement={({ data }) => { // NOSONAR
                  const family = data as _Family;
                  return <FamilyWidget family={family} />;
                }}
              />
            </group>
          </group>
        )}
        {(familyData?.produits?.length as number) > 0 && (
          <group data-direction="column" data-fit="1">
            <group data-align="center">
              <group data-gap="5" data-align="center" data-space="10">
                <Text data-accent="" data-text-size="large" data-wrap="true" data-weight="700">
                  famille.titres.produits
                </Text>
              </group>
              <ListView
                keyField="uuid"
                data-template="170"
                view={ListViewType.Block}
                listProps={{ 'data-gap': 15 }}
                dataSource={familyData?.produits}
                getItemElement={({ data }) => { // NOSONAR
                  const product = {
                    ...data,
                    familles: data?.familles?.length ? data.familles : [familyData],
                  } as Product;
                  return <ProductWidget product={product} />;
                }}
              />
            </group>
          </group>
        )}
      </wrapper>
    </section>
  );
};
export default Family;
