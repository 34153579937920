import { Breadcrumb, Dropdown } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import BrandIcon, { BrandIcons } from '../../../brands/brandIcon';
import ProductWidget from '../../../components/productWidget';
import Button from '../../../components/shared/button';
import ListView from '../../../components/shared/list/listView';
import { ListViewType } from '../../../components/shared/list/useListHook';
import Loader from '../../../components/shared/loader';
import Popup from '../../../components/shared/popup';
import Separator from '../../../components/shared/separator';
import { Text } from '../../../components/shared/text';
import { getClientType, isTypeGarantiesAllowed } from '../../../project/helpers';
import { useGetFamilyQuery } from '../../../store/family/familyApi';
import { Family } from '../../../store/family/familyModels';
import { useAppSelector, useGetFamiliesQueryState } from '../../../store/hooks';
import { Product, ProductWarrantyType } from '../../../store/product/productModels';
import { currentCountryUuid } from '../../../store/sideData/siteDataSlice';
import { WarrantyRequest } from '../../../store/warrantyRequest/warrantyRequestModels';
import { getKeyUpHandler } from '../../../utils/helpers';

const ProductCatalogPopup: React.FC<{
  title?: string;
  isOpen?: boolean;
  onClose: () => void;
  onSelect: (product: Product) => void;
  request: WarrantyRequest;
}> = ({ title = '', isOpen, onClose, request, onSelect }) => {
  const user = useAppSelector((state) => state.auth);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const isSatc = useAppSelector((state) => state.components.satcMode);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';
  const { data: families } = useGetFamiliesQueryState();
  const [selected, setSelected] = useState<Family | null>(null);
  const [selectedPath, setSelectedPath] = useState<Family[]>([]);
  const leftItems = selected ? selected.children : families;

  const {
    data: family,
    isLoading: isFamilyLoading,
    isFetching: isFamilyFetching,
  } = useGetFamilyQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id: selected?.uuid, satc_mode: isSatc },
    { skip: !selected || !pays_uuid || !univers?.uuid || !lang }
  );
  const familyData = selected ? family : null;
  const isLoading = isFamilyFetching || isFamilyLoading;

  const clearSelection = () => {
    setSelected(null);
    setSelectedPath([]);
  };

  const breadCrumbItems = [
    {
      title: <Text>ariane.menuNavigationTitle</Text>,
      items:
        selectedPath?.length > 0
          ? [null, ...selectedPath]
              .slice(0, selectedPath.length)
              .map((f, i) => ({
                key: f?.uuid ?? 'home',
                label: (
                  <group
                    data-align="center"
                    data-gap="10"
                    onClick={
                      i === 0
                        ? clearSelection
                        : () => {
                            setSelected(selectedPath[i - 1]);
                            setSelectedPath(selectedPath.slice(0, i));
                          }
                    }
                    onKeyUp={getKeyUpHandler()}
                  >
                    <icon data-icon-size="small">expand_less</icon>
                    <Text>{f?.nom ?? 'ariane.accueil'}</Text>
                  </group>
                ),
              }))
              .reverse()
          : [],
    },
  ];

  if (selected) {
    breadCrumbItems.push({ title: selected.nom } as any);
  }

  useEffect(() => {
    if (!isOpen) {
      clearSelection();
    }
  }, [isOpen]);

  const productsEnCompte = useMemo(
    () =>
      familyData?.produits?.filter((product: Product) => {
        const clientType = getClientType(user, product.reseau?.uuid);
        let warrantyClientEnCompte = clientType ? product.typesGarantie[clientType] : ({} as ProductWarrantyType);
        return (
          product.isEnCompte && product.code && isTypeGarantiesAllowed(warrantyClientEnCompte, request.typedemande)
        );
      }),
    [familyData, request, user]
  );

  const otherProducts = useMemo(
    () =>
      familyData?.produits?.filter((product: Product) => {
        const clientType = getClientType(user, product.reseau?.uuid);
        let warrantyClientEnCompte = clientType ? product.typesGarantie[clientType] : ({} as ProductWarrantyType);
        return !product.isEnCompte || !isTypeGarantiesAllowed(warrantyClientEnCompte, request.typedemande);
      }),
    [familyData, request, user]
  );

  return (
    <>
      {isOpen && (
        <Popup
          //windowContProps={{ 'data-length': 700 }}
          fixSize="large"
          title={title}
          onClose={onClose}
        >
          <group data-align="center" data-space="10">
            <Breadcrumb
              data-space-left="15"
              items={breadCrumbItems}
              itemRender={(route: any) => { // NOSONAR
                if (route.items?.length) {
                  return (
                    <Dropdown menu={{ items: route.items }} placement="bottomLeft" trigger={['click']} arrow>
                      <a>
                        <group data-align="center">
                          <Text dataWeight="700">{route.title}</Text>
                          <icon data-icon-size="small">expand_more</icon>
                        </group>
                      </a>
                    </Dropdown>
                  );
                } else {
                  return route.title;
                }
              }}
            />
          </group>
          <view data-vertical="" data-space="10" data-border="none" data-adaptive="600">
            <view data-border="none" data-scroll="">
              <group data-space="10">
                <group data-gap="5" data-align="center" data-space="10">
                  <Text data-accent="" data-text-size="large" data-wrap="true" data-weight="700">
                    garantie.demande.etapes.1.famillesTitre
                  </Text>
                </group>
                <div className="list_menu">
                  <ul>
                    {leftItems?.map((f) => {
                      return (
                        <li // NOSONAR
                          key={f.uuid}
                          onClick={() => {
                            setSelected(f);
                            setSelectedPath([...selectedPath, f]);
                          }}
                          onKeyUp={getKeyUpHandler()}
                        >
                          <label data-space-left="20" data-align="center" data-min-height="40">
                            <text data-length="autofit">{f.nom}</text>
                            {f.children?.length > 0 && (
                              <Button mini large material>
                                <icon data-opacity="light">
                                  <BrandIcon>{BrandIcons.ExpandIcon}</BrandIcon>
                                </icon>
                              </Button>
                            )}
                          </label>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </group>
            </view>
            <Separator vertical="fit" />
            <view data-border="none" data-scroll="">
              {isLoading && <Loader />}
              <group data-space="10">
                <group data-gap="5" data-align="center" data-space="10">
                  <Text data-accent="" data-text-size="large" data-wrap="true" data-weight="700">
                    garantie.demande.etapes.1.produitsTitre
                  </Text>
                </group>
                {(familyData?.produits?.length as number) > 0 ? (
                  <group data-direction="column" data-gap="5">
                    {(productsEnCompte?.length as number) > 0 && (
                      <>
                        <Text data-text-size="small" data-space="10">
                          garantie.demande.etapes.1.autocomplete.encompte
                        </Text>
                        <ListView
                          keyField="uuid"
                          data-template="170"
                          view={ListViewType.Block}
                          //  scrollerProps={{ "data-space": 10 }}
                          listProps={{ 'data-gap': 15 }}
                          dataSource={productsEnCompte}
                          getItemElement={({ data }) => { // NOSONAR
                            const product = {
                              ...data,
                              familles: data?.familles?.length ? data.familles : [familyData],
                            } as Product;
                            return (
                              <ProductWidget
                                product={product}
                                noLink
                                onClick={() => {
                                  onSelect(product);
                                  onClose();
                                }}
                              />
                            );
                          }}
                        />
                      </>
                    )}
                    {(otherProducts?.length as number) > 0 && (
                      <>
                        <Text data-text-size="small" data-space="10">
                          garantie.demande.etapes.1.autocomplete.autres
                        </Text>
                        <ListView
                          keyField="uuid"
                          data-template="170"
                          view={ListViewType.Block}
                          //  scrollerProps={{ "data-space": 10 }}
                          listProps={{ 'data-gap': 15 }}
                          dataSource={otherProducts}
                          getItemElement={({ data }) => { // NOSONAR
                            const product = {
                              ...data,
                              familles: data?.familles?.length ? data.familles : [familyData],
                            } as Product;
                            return <ProductWidget product={product} noLink />;
                          }}
                        />
                      </>
                    )}
                  </group>
                ) : (
                  familyData && (
                    <Text data-text-size="small" data-space="10">
                      garantie.demande.etapes.1.noproduits
                    </Text>
                  )
                )}
              </group>
            </view>
          </view>
        </Popup>
      )}
    </>
  );
};

export default ProductCatalogPopup;
