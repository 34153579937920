import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useParams } from 'react-router-dom';
import ArticleInfoHeader from '../../components/article/articleInfoHeader';
import { ArticleUseCaseButton } from '../../components/article/articleUseCases';
import BackButton from '../../components/backButton';
import BreadcrumbWidget from '../../components/breadcrumbWidget';
import EmptyPicture from '../../components/emptyPicture';
import Button from '../../components/shared/button';
import ImageMagnifier from '../../components/shared/imageMagnifier';
import Loader from '../../components/shared/loader';
import Popup from '../../components/shared/popup';
import Scroll from '../../components/shared/scroll';
import Separator from '../../components/shared/separator';
import { Text } from '../../components/shared/text';
import WarrantyRequestTypeSelect from '../../components/warrantyRequest/warrantyRequestTypeSelection';
import { APP_URL } from '../../project/defines';
import { getAppUrl, isGroupSSO } from '../../project/helpers';
import { setArticle } from '../../store/analyticsSlice';
import { useGetArticleQuery, useGetUseCasesQuery } from '../../store/article/articleApi';
import { Article } from '../../store/article/articleModels';
import { setShowTarifMessage } from '../../store/componentsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentCountryUuid } from '../../store/sideData/siteDataSlice';
import { UniversInfo } from '../../store/univers/universModels';
import { hasWarrantyAccess } from '../../utils/helpers';
import AddToCardButton from './addToCardButton';
import ArticlePrices from './articlePrices';
import CardPaymentIcon from './cardPaymentIcon';

const ArticleDetails: React.FC = () => { // NOSONAR
  const { id, reseau_uuid } = useParams();
  const dispatch = useAppDispatch();
  const state = useAppSelector((state) => state);
  const univers = useAppSelector((state) => state.siteData?.universInfo?.univers);
  const lang = useAppSelector((state) => state.language.currentLanguage);
  const { listeSSO } = useAppSelector((state) => state.siteData.universInfo) ?? ({} as UniversInfo);
  const pays_uuid = useAppSelector(currentCountryUuid) ?? '';

  const {
    data: article,
    isError,
    isSuccess,
    isFetching,
  } = useGetArticleQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id, reseau_uuid },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang }
  );

  const { data: useCases } = useGetUseCasesQuery(
    { univers_uuid: univers?.uuid ?? '', lang, pays_uuid, id, reseau_uuid },
    { skip: !id || !pays_uuid || !univers?.uuid || !lang || !reseau_uuid }
  );

  const { articleReseau, article_remplacant, libelle, commentaire, remplacement } = article ?? ({} as Article);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');

  let images = article?.fichiers.map((i) => i.image);
  images = Array.isArray(images) ? images : [images ?? ''];

  useEffect(() => {
    article && setImage(article?.image ?? (images?.length ? images[0] : ''));
  }, [article]);

  useEffect(() => {
    if (article) {
      dispatch(setArticle(article));
    }
  }, [article, dispatch]);

  useEffect(() => {
    if (!isGroupSSO(listeSSO)) {
      dispatch(setShowTarifMessage(!!article?.articleReseau?.prix_ht));
    }
  }, [article, listeSSO, dispatch]);

  return (
    <>
      {isOpen && (
        <Popup title="garantie.demande.modal.titre" onClose={() => setIsOpen(false)}>
          <WarrantyRequestTypeSelect
            useCases={useCases}
            article_uuid={article?.uuid}
            callback={() => {
              setIsOpen(false);
            }}
          />
        </Popup>
      )}
      {isSuccess && article ? (
        <section>
          {isFetching && <Loader />}
          <wrapper>
            <BreadcrumbWidget />
            <group data-gap="10" data-align="start" data-adaptive="800">
              <group data-wrap="no" data-length="400">
                {images?.length > 1 ? (
                  <group data-width="auto" data-direction="column">
                    <group
                      data-justify="center"
                      data-align="center"
                      data-contain
                      data-direction="column"
                      data-gap="10"
                      style={{ height: '400px' }}
                    >
                      <Scroll vertical>
                        <group data-direction="column" data-wrap="no">
                          {images.map((item, i) => (
                            <group data-space="5" key={item}>
                              <picture
                                data-interactive=""
                                data-length="60"
                                data-height="60"
                                data-background="highlight"
                                data-border=""
                                data-radius="5"
                                onMouseEnter={() => {
                                  setImage(item);
                                }}
                              >
                                <img src={item} alt={libelle} />
                              </picture>
                            </group>
                          ))}
                        </group>
                      </Scroll>
                    </group>
                  </group>
                ) : null}
                <group data-space="5">
                  <group data-justify="center" data-align="center" data-border="" data-radius="5">
                    {image ? (
                      <ImageMagnifier
                        magnifierHeight={200}
                        magnifierWidth={200}
                        height="400px"
                        zoomLevel={2}
                        src={image || images[0]}
                        cover={isMobile}
                      />
                    ) : (
                      <EmptyPicture size="xxx-large" />
                    )}
                  </group>
                </group>
              </group>
              <group
                data-direction="column"
                data-gap="10"
                data-space="5"
                data-length="autofit"
                data-width="auto"
                data-align="start"
              >
                <group data-direction="column" data-gap="5">
                  <ArticleInfoHeader article={article} />
                </group>

                <group data-wrap="no">
                  <group>
                    {articleReseau && <ArticlePrices article={article} />}
                    {article_remplacant && (
                      <group data-text-size="medium" data-gap="5">
                        <Text>article.replacePar</Text>
                        <Link
                          to={getAppUrl(articleReseau ? APP_URL.ArticleReseau : APP_URL.Article, {
                            routeParams: {
                              id: article_remplacant.uuid || '',
                              reseau_uuid: article_remplacant.articleReseau?.reseau_uuid,
                            },
                          })}
                        >
                          <Text data-type="link">{article_remplacant.reference}</Text>
                        </Link>
                      </group>
                    )}
                    {articleReseau?.libelle_gescom && (
                      <group data-gap="10">
                        <Text>{articleReseau.libelle_gescom}</Text>
                      </group>
                    )}
                    {commentaire && (
                      <group data-background="main-lighter" data-space="10" data-radius="5">
                        <Text data-wrap="wrap">{commentaire}</Text>
                      </group>
                    )}
                  </group>
                  <group data-width="auto" data-space="15" data-align="start">
                    <CardPaymentIcon article={article} />
                  </group>
                </group>

                <group data-gap="10" data-align="center" data-position="bottom">
                  {/*<separator horizontal="" data-adaptive="desktop"></separator>*/}
                  <space data-height="20"></space>
                  <AddToCardButton article={article} />
                  <Separator vertical data-adaptive="desktop" />
                  <ArticleUseCaseButton article={article} />
                  {hasWarrantyAccess(state) && (
                    <Button
                      data-length="forcefit"
                      accent
                      icon="Swap_Horiz"
                      text="article.boutons.remplacement"
                      disabled={!remplacement}
                      textFirst={false}
                      onClick={(e) => {
                        setIsOpen(true);
                      }}
                    ></Button>
                  )}

                  {window.history.length >= 3 && <BackButton />}
                </group>
              </group>
            </group>
          </wrapper>
        </section>
      ) : (
        <group data-space="30" data-justify="center">
          {!isError && <Loader />}
        </group>
      )}
    </>
  );
};
export default ArticleDetails;
